import { AbstractControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map, merge, Observable, startWith } from 'rxjs';
import { ErrorMessages } from './error-messages.model';

const defaultErrorMessages: ErrorMessages = {
  required: 'This field is required',
  email: 'Please enter a valid email address',
  minlength: (err) => `Text should be at least ${err['requiredLength']} characters long`,
  maxlength: (err) => `Text should be at most ${err['requiredLength']} characters long`,
  min: (err) => `Value should be at least ${err['min']}`,
  max: (err) => `Value should be at most ${err['max']}`,
  pattern: (err) => `Value does not match the pattern "${err['requiredPattern']}"`,
};

export const selectFirstErrorMessage: (
  ctrl: AbstractControl,
  errorMessages?: ErrorMessages,
) => Observable<string | null> = (ctrl, errorMessages) =>
  merge(ctrl.valueChanges.pipe(debounceTime(250)), ctrl.statusChanges).pipe(
    map(() => ctrl.errors),
    distinctUntilChanged(),
    startWith(null),
    map((errors) => (errors && Object.entries(errors)[0]) || []),
    distinctUntilChanged(),
    map(([errorKey, errorValue]) => {
      if (!errorKey) {
        return null;
      }

      const messageOrFn = errorMessages?.[errorKey] ?? defaultErrorMessages[errorKey];

      if (!messageOrFn) {
        return null;
      }

      if (typeof messageOrFn === 'string') {
        return messageOrFn;
      }

      return messageOrFn(errorValue);
    }),
  );
