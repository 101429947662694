import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, finalize, map, Observable, tap } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { INITIAL_LOADING_STATUS, LoadingStatus } from './app-loading.model';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AppLoadingService {
  private readonly loadingStack$$ = new BehaviorSubject<Array<{ id: string; description?: string }>>([
    INITIAL_LOADING_STATUS,
  ]);

  /**
   * The oldest active loading status in the stack
   */
  readonly loadingStatus$: Observable<LoadingStatus> = this.loadingStack$$.pipe(
    // We want the oldest elements first
    map((stack) => stack.slice().reverse()),
    map((stack) => {
      const firstStatusWithDescription = stack.find((status) => !!status.description);
      return firstStatusWithDescription || stack[0];
    }),
    map((latest) => {
      if (latest) {
        const { description } = latest;
        return {
          loading: true,
          description,
        };
      } else {
        return { loading: false };
      }
    }),
  );

  readonly isLoading$ = this.loadingStatus$.pipe(map((status) => status.loading));

  setLoading(description?: string) {
    const id = uuid();

    const newStack = [
      { id, description },
      ...this.loadingStack$$.value.filter((status) => status.id !== INITIAL_LOADING_STATUS.id),
    ];
    this.loadingStack$$.next(newStack);

    return id;
  }

  /**
   * RxJS operator to set a loading status and clear it when the first value is emitted, on error, or when the observable completes
   */
  setLoadingUntilFirst<T>(description?: string) {
    let loadingId: string | undefined = this.setLoading(description);

    const clear = () => {
      if (loadingId) {
        this.clearLoading(loadingId);
        loadingId = undefined;
      }
    };

    return (source: Observable<T>) => {
      return source.pipe(tap(clear), finalize(clear));
    };
  }

  clearLoading(id: string) {
    const newStack = this.loadingStack$$.value.filter((item) => item.id !== id);
    this.loadingStack$$.next(newStack);
  }

  clearAll() {
    this.loadingStack$$.next([]);
  }
}
