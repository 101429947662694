import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatError } from '@angular/material/form-field';
import { Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { ButtonComponent } from '@examdojo/core/button';
import { emailValidator } from '@examdojo/core/form';
import { FormConfirmFn, SubmitButtonDirective } from '@examdojo/core/form-submit-button';
import { IconComponent } from '@examdojo/core/icon';
import { TextInputComponent } from '@examdojo/core/input';
import { UserPasswordInputComponent } from '@examdojo/core/user-password-input';
import { ToastService } from '@examdojo/toast';
import { ExamdojoAuthService } from '../abstract-auth.service';
import { AuthUrlParts } from '../auth.model';

@Component({
  selector: 'dojo-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatButtonModule,
    IconComponent,
    ButtonComponent,
    RouterLink,
    TextInputComponent,
    UserPasswordInputComponent,
    MatError,
    SubmitButtonDirective,
    TranslocoPipe,
  ],
})
export class SignUpComponent {
  constructor(
    private readonly router: Router,
    private readonly authService: ExamdojoAuthService,
    private readonly toastService: ToastService,
  ) {}

  error = '';

  readonly loginUrl = ['/', AuthUrlParts.Login];

  readonly form = new FormGroup(
    {
      email: new FormControl('', {
        validators: [Validators.required, emailValidator],
        nonNullable: true,
      }),
      password: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      firstName: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
      lastName: new FormControl('', {
        validators: [Validators.required],
        nonNullable: true,
      }),
    },
    {
      updateOn: 'submit',
    },
  );

  readonly confirmFn: FormConfirmFn<typeof this.form> = async (form) => {
    const { email, password, firstName, lastName } = form.getRawValue();

    this.form.controls.email.setErrors(null);

    const { data, error } = await this.authService.signUp(email, password, firstName, lastName);

    if (error) {
      this.error = error.message;
      return;
    }

    if (data.user && !data.session) {
      return;
    }

    this.router.navigateByUrl(`/`);
  };
}
