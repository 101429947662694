import { LanguageCode } from './language';

export const SUPPORTED_LANGUAGES = ['en', 'de', 'fr'] as const satisfies LanguageCode[];

export type SupportedLanguage = (typeof SUPPORTED_LANGUAGES)[number];

export const DEFAULT_LANGUAGE = 'en' as const satisfies SupportedLanguage;

export const LANGUAGE_TO_LABEL: Record<SupportedLanguage, string> = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
};
