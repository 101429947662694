import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

export const REDIRECT_TO_PARAM = 'redirectTo';

export const redirectToGuard: CanActivateFn = (route) => {
  const router = inject(Router);
  const redirectToUrl = route.queryParams[REDIRECT_TO_PARAM];

  if (redirectToUrl) {
    return router.parseUrl(redirectToUrl);
  }

  return true;
};
