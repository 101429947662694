import { keys } from './object-utils';
/** Removes excessive properties from the given object based on allowed keys. */ 
export function sanitizeObject<T extends object>(obj: T, allowedKeys: Array<keyof T>): T {
  return keys(obj).reduce((sanitizedObj, key) => {
    if (!allowedKeys.includes(key)) {
      return sanitizedObj;
    }
    return { ...sanitizedObj, [key]: obj[key] };
  }, {} as T);
}
