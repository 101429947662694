import { Injectable } from '@angular/core';
import { environment } from '@examdojo/core/environment';
import { isNotNullish, isNullish } from '@examdojo/core/util/nullish';
import { ensureArray } from '@examdojo/util/ensure-array';
import { OrArray } from '@ngneat/elf';
import { createClient } from '@supabase/supabase-js';
import { GenericSchema } from '@supabase/supabase-js/dist/module/lib/types';
import { v4 as uuid } from 'uuid';
import { SUPABASE_AUTH_STORAGE_KEY } from './supabase-auth-storage-key.model';
import { SupabaseSelectQueryConfig } from './supabase-select-query-config.model';

@Injectable({
  providedIn: 'root',
})
export abstract class SupabaseService<T extends Record<string, unknown> = {}> {
  readonly client = createClient<T>(environment.supabase.apiUrl, environment.supabase.publicKey, {
    auth: {
      storageKey: SUPABASE_AUTH_STORAGE_KEY,
    },
  });

  /**
   * Returns the public URL of the specified file in the specified bucket
   */
  getPublicUrl(bucketName: string, filePath: string): string {
    const { data } = this.client.storage.from(bucketName).getPublicUrl(filePath);
    return data.publicUrl;
  }

  /**
   * Uploads a file to the specified bucket.
   * Returns the path of the uploaded file.
   */
  async uploadFile(bucketName: string, file: File | ArrayBuffer, type: string, name?: string): Promise<string> {
    const fileName = name ?? uuid();

    const { data, error } = await this.client.storage.from(bucketName).upload(fileName, file, {
      contentType: type,
    });

    if (error) {
      throw error;
    }

    return data.path;
  }

  /**
   * Generates a signed URL for the specified file in the specified bucket.
   * The signed URL is valid for the specified number of seconds (default: 3600).
   */
  async getSignedUrlForFile(bucketName: string, filePath: string, expiresIn = 3600): Promise<string> {
    const { data, error } = await this.client.storage.from(bucketName).createSignedUrl(filePath, expiresIn);

    if (error) {
      throw error;
    }

    return data.signedUrl;
  }

  createSelectQuery<TableName extends string & keyof GenericSchema['Tables']>(
    schema: string,
    tableName: TableName,
    selectQueryConfig: SupabaseSelectQueryConfig,
  ) {
    const { startRow, endRow, sortBy, filterBy } = selectQueryConfig;
    let query = this.client.schema(schema).from(tableName).select('*', { count: 'exact' }).throwOnError();

    if (isNotNullish(startRow) && isNotNullish(endRow)) {
      query = query.range(startRow, endRow);
    }

    // Apply sorting
    if (sortBy?.length) {
      sortBy.forEach((sort) => {
        const [colId, fieldId] = sort.colId.split('.');

        if (!fieldId) {
          query = query.order(colId, {
            ascending: sort.sort === 'asc',
          });
        } else {
          // sort by JSON field in the column
          query = query.order(`${colId}->>${fieldId}`, {
            ascending: sort.sort === 'asc',
          });
        }
      });
    }

    // Apply filters
    if (filterBy) {
      Object.entries(filterBy).forEach(([key, filter]) => {
        const [colId, fieldId] = key.split('.');

        if (isNullish(filter)) {
          return;
        }

        if (filter.filterType === 'text' && filter.type === 'contains') {
          if (fieldId) {
            query = query.ilike(`${colId}->>${fieldId}`, `%${filter.filter}%`);
          } else {
            query = query.ilike(key, `%${filter.filter}%`);
          }
        } else if (filter.filterType === 'number' && filter.type === 'equals') {
          if (fieldId) {
            query = query.eq(`${colId}->>${fieldId}`, filter.filter);
          } else {
            query = query.eq(key, filter.filter);
          }
        } else if (filter.filterType === 'set' && filter.values) {
          const nullishValues = filter.values.filter((value) => isNullish(value));
          const nonNullishValues = filter.values.filter((value) => isNotNullish(value));

          if (nullishValues.length) {
            query = nonNullishValues.length
              ? query.or(`${key}.is.null,` + `${key}.in.(${nonNullishValues.join(',')})`)
              : query.is(key, null);
          } else if (nonNullishValues.length) {
            query = query.in(
              key,
              nonNullishValues.map((value) => value.toString()),
            );
          }
        } else {
          console.warn(`Unsupported filter type: ${filter.filterType}`);
        }
      });
    }

    return query;
  }

  async removeFiles(bucketName: string, paths: OrArray<string>) {
    const { data, error } = await this.client.storage.from(bucketName).remove(ensureArray(paths));

    if (error) {
      throw error;
    }

    return data;
  }

  /**
   * Generates a signed URLs for the specified files in the specified bucket.
   * The signed URLs are valid for the specified number of seconds (default: 3600).
   */
  async getSignedUrlsForFiles(bucketName: string, filePaths: string[], expiresIn = 3600) {
    const { data, error } = await this.client.storage.from(bucketName).createSignedUrls(filePaths, expiresIn);

    if (error) {
      throw error;
    }

    return data;
  }
}
