import { Injectable } from '@angular/core';
import { UserState, UserStoreModel, UserUIModel } from '@examdojo/models/user';
import { QueryEntity } from '@examdojo/state';
import { UserStore } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserQuery extends QueryEntity<UserState, UserStoreModel, UserUIModel, 'id'> {
  constructor(protected override store: UserStore) {
    super(store);
  }

  override readonly toUIModelFn = (entity: UserStoreModel): UserUIModel => {
    return {
      ...entity,
      fullName: `${entity.first_name} ${entity.last_name}`,
    };
  };
}
