import { APP_INITIALIZER, Provider } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { AuthService } from './auth/auth.service';
import { LanguageService } from './shared/i18n/language.service';

export const appInitializer =
  (authService: AuthService, languageService: LanguageService, faIconLibrary: FaIconLibrary) =>
  async (): Promise<void> => {
    console.debug('[APP_INITIALIZER]: starting');

    console.debug('[APP_INITIALIZER]: loading user');

    try {
      await authService.loadUser();

      languageService.loadLanguageFromLocalStorage();

      faIconLibrary.addIconPacks(fal);
    } catch (err) {
      console.error('[APP_INITIALIZER]: errored', err);
    }

    console.debug('[APP_INITIALIZER]: finished');
  };

export const APP_INITIALIZER_PROVIDER: Provider = {
  provide: APP_INITIALIZER,
  multi: true,
  useFactory: appInitializer,
  deps: [AuthService, LanguageService, FaIconLibrary],
};
