<div class="mx-auto flex h-full w-full max-w-[380px] flex-col justify-center px-4 py-4">
  <div class="mb-8">
    <img src="assets/images/sign-in-image.svg" class="mx-auto mb-6 max-w-[280px]" alt="Banner" />
  </div>

  <div class="text-typography-primary dark:font-medium-dark pb-6 text-center text-xl font-medium">
    Sign in to your account
  </div>

  <form [formGroup]="form" class="flex w-full flex-col">
    <y42-text-input
      label="Email"
      [formCtrl]="form.controls.email"
      data-test="sign-in-input-email"
      autocomplete="email"
      [errorMessages]="emailErrorMessages"
    ></y42-text-input>

    <y42-password-input
      class="mt-4"
      label="Password"
      [formCtrl]="form.controls.password"
      data-test="sign-in-input-password"
      autocomplete="current-password"
      [errorMessages]="{
        required: 'Password is required'
      }"
    ></y42-password-input>

    <y42-button
      color="primary"
      class="w-full"
      [class]="'mt-6'"
      submitButton
      [confirmFn]="confirmFn"
      data-test="login-button"
    >
      Sign in
    </y42-button>

    @if (error) {
      <mat-error class="mt-2 text-center">
        {{ error }}
      </mat-error>
    }
  </form>

  <p
    class="text-typography-secondary py-4 text-center"
    [innerHTML]="
      'examdojo.sign_up_terms_and_conditions'
        | transloco
          : {
              tac_url: 'https://examdojo.com/legal/terms-and-conditions',
              privacy_policy_url: 'https://examdojo.com/legal/privacy-policy'
            }
    "
  ></p>

  <p class="action-text flex justify-center gap-2 text-center">
    <span>{{ 'examdojo.new_user_question' | transloco }}</span>
    <a class="dashed-link" [routerLink]="registerUrl">{{ 'examdojo.registration' | transloco }}</a>
  </p>
</div>
