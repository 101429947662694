import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { UserRole } from '@examdojo/models/user';
import { ToastService } from '@examdojo/toast';
import { map } from 'rxjs';
import { DEFAULT_ROOT_URL_PART } from '../../app.model';
import { UserService } from '../user.service';

export function getUserRoleGuard(role: UserRole): CanActivateFn {
  return () => {
    const userService = inject(UserService);
    const toastService = inject(ToastService);
    const router = inject(Router);

    return userService.doesCurrentUserHaveRoleOrHigher(role).pipe(
      map((isGranted) => {
        if (!isGranted) {
          toastService.warning('You are not authorized to access this page');
          return router.parseUrl(DEFAULT_ROOT_URL_PART);
        }

        return true;
      }),
    );
  };
}
