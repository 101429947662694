import { Injectable } from '@angular/core';
import posthog from 'posthog-js';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PosthogFeatureFlagService {
  constructor() {
    posthog.onFeatureFlags(() => {
      this.isInitialized$$.next(true);
    });
  }

  private readonly isInitialized$$ = new BehaviorSubject(false);
  readonly isInitialized$ = this.isInitialized$$.asObservable();

  isFeatureEnabled(flag: string) {
    return posthog.isFeatureEnabled(flag);
  }

  /**
   * Gets the variant value of a multivariate feature flag.
   *
   * Does not work with 'Release toggle' (boolean) feature flags.
   * @param flag
   */
  getMultivariateFeatureFlag(flag: string) {
    return posthog.getFeatureFlag(flag);
  }
}
