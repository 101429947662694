import { BehaviorSubject } from 'rxjs';

const instancesMapofSubjects = new WeakMap<object, Map<string, BehaviorSubject<unknown>>>();
const getInstanceSubject = (instance: object, observablePropertyName: string): BehaviorSubject<unknown> => {
  let existingInstanceMap = instancesMapofSubjects.get(instance);
  if (!existingInstanceMap) {
    const createdSubjectsMap = new Map<string, BehaviorSubject<unknown>>();
    instancesMapofSubjects.set(instance, createdSubjectsMap);
    existingInstanceMap = createdSubjectsMap;
  }

  let foundSubject = existingInstanceMap.get(observablePropertyName);
  if (!foundSubject) {
    const createdSubject = new BehaviorSubject<unknown>(undefined);
    existingInstanceMap.set(observablePropertyName, createdSubject);
    foundSubject = createdSubject;
  }
  return foundSubject;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function InputObservable<T>(options?: { propertyNameSuffix?: string }): any {
  return function (targetPrototype: T, propertyName: keyof T, descriptor: PropertyDescriptor) {
    const observablePropertyName = (propertyName as string) + ((options && options.propertyNameSuffix) || '$');
    const getterFx = function (this: object) {
      return getInstanceSubject(this, observablePropertyName).getValue();
    };
    const setterFx = function (this: object, value: unknown): void {
      return getInstanceSubject(this, observablePropertyName).next(value);
    };

    Object.defineProperty(targetPrototype, observablePropertyName, {
      get(this: object) {
        return getInstanceSubject(this, observablePropertyName).asObservable();
      },
      enumerable: true,
    });

    if (descriptor) {
      const originalSetter = descriptor.set;
      const originalGetter = descriptor.get;
      descriptor.get = getterFx;
      descriptor.set = function (this: object, value: unknown) {
        if (originalSetter) {
          originalSetter.call(this, value);
        }
        if (originalGetter) {
          value = originalGetter.call(this);
        }
        return setterFx.call(this, value);
      };
    } else {
      Object.defineProperty(targetPrototype, propertyName, {
        get: getterFx,
        set: setterFx,
        enumerable: true,
      });
    }
  };
}
