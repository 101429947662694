@for (digitControl of formArray.controls; track digitControl; let i = $index) {
  @if (i === 3) {
    <span class="text-stone-300">-</span>
  }
  <dojo-text-input
    class="ote-input"
    [ngClass]="{ 'filled-input': formArray.controls[i].value }"
    [formCtrl]="digitControl"
    (input)="focusNextInput(i)"
    (paste)="onPaste($event)"
    (keyup)="onKeyup(i, $event)"
    [hasInitialFocus]="i === 0"
  />
}
