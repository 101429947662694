<dojo-text-input
  [formCtrl]="formCtrl"
  [placeholder]="placeholder"
  [compact]="compact"
  [label]="label"
  [type]="'text'"
  [hasInitialFocus]="hasInitialFocus"
  [fullWidthField]="fullWidthField"
  id="searchInput"
>
  <ng-container y42InputPrefix>
    <y42-icon [icon]="icon" />
  </ng-container>
  <ng-container y42InputSuffix *ngIf="formCtrl.value.length > 0">
    <button mat-icon-button (click)="formCtrl.reset('')">
      <y42-icon [icon]="'times'" [size]="16" />
    </button>
  </ng-container>
</dojo-text-input>
