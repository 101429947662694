import { FormGroup } from '@angular/forms';
import { markAllAs } from '@examdojo/angular/forms';
import { takePromise } from '@examdojo/core/util/take-promise';
import { filter } from 'rxjs';

export async function markFormAsSubmitted<TFormGroup extends FormGroup>(form: TFormGroup): Promise<void> {
  markAllAs('dirty', form);
  markAllAs('touched', form);
  if (form.pending) {
    await takePromise(form.statusChanges.pipe(filter((status) => status !== 'PENDING')));
  }
}
