import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatError } from '@angular/material/form-field';
import { Router, RouterLink } from '@angular/router';
import { ButtonComponent } from '@examdojo/core/button';
import { emailValidator, ErrorMessages } from '@examdojo/core/form';
import { FormConfirmFn, SubmitButtonDirective } from '@examdojo/core/form-submit-button';
import { IconComponent } from '@examdojo/core/icon';
import { TextInputComponent } from '@examdojo/core/input';
import { PasswordInputComponent } from '@examdojo/core/password-input';
import { ToastService } from '@examdojo/toast';
import { TranslocoPipe } from '@jsverse/transloco';
import { ExamdojoAuthService } from '../abstract-auth.service';
import { AuthUrlParts } from '../auth.model';

@Component({
  selector: 'dojo-sign-in',
  templateUrl: './sign-in.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    MatError,
    ReactiveFormsModule,
    IconComponent,
    RouterLink,
    ButtonComponent,
    TextInputComponent,
    PasswordInputComponent,
    SubmitButtonDirective,
    TranslocoPipe,
  ],
})
export class SignInComponent {
  constructor(
    private readonly authService: ExamdojoAuthService,
    private readonly router: Router,
    private readonly toastService: ToastService,
  ) {}

  error = '';

  readonly registerUrl = ['/', AuthUrlParts.Register];

  readonly emailErrorMessages: ErrorMessages = {
    required: 'Email is required',
    email: 'Enter a valid email',
    // ...ERROR_KEY_TO_MESSAGE,
  };

  readonly form = new FormGroup(
    {
      email: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, emailValidator],
      }),
      password: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    },
    {
      updateOn: 'submit',
    },
  );

  readonly confirmFn: FormConfirmFn<typeof this.form> = async (form) => {
    const { email, password } = form.getRawValue();
    try {
      await this.authService.signIn(email, password);
      this.error = '';
      this.toastService.success('Signed in successfully', { duration: 1500 });

      this.router.navigateByUrl('/');
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.error = err.message;
      }

      return;
    }
  };
}
