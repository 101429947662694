import { ChangeDetectionStrategy, Component } from '@angular/core';
import { connectState } from '@examdojo/angular/util';
import { UserQuery } from '../user/user.query';

@Component({
  selector: 'exam-home',
  standalone: true,
  imports: [],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomeComponent {
  constructor(private readonly userQuery: UserQuery) {}

  readonly state = connectState({
    user: this.userQuery.active$,
  });
}
