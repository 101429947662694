export interface DistributionSettings {
  type: DistributionTypes;
}

export enum DistributionTypes {
  Area = 'area',
  Bar = 'bar',
  Column = 'column',
  Line = 'line',
}
