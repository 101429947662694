import { Directive, input, TemplateRef, ViewContainerRef } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { UserRole } from '@examdojo/models/user';
import { UntilDestroy } from '@ngneat/until-destroy';
import { switchMap, tap } from 'rxjs';
import { UserService } from '../user.service';

@UntilDestroy()
@Directive({
  selector: '[examIfUserHasRole]',
  exportAs: 'ifUserHasRole',
  standalone: true,
})
export class IfUserHasRoleDirective {
  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly userService: UserService,
  ) {
    toObservable(this.examIfUserHasRole)
      .pipe(
        switchMap((role) => this.userService.doesCurrentUserHaveRoleOrHigher(role)),
        tap((isGranted) => {
          this.viewContainer.clear();
          if (isGranted) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
        }),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  readonly examIfUserHasRole = input.required<UserRole>();
}
