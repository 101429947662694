import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { InternationalizationService } from '../internationalization.service';
import { LanguageCode } from '../language';
import { LocalizedString } from '../localized-string.model';
import { DEFAULT_LANGUAGE } from '../supported-languages';

@Injectable({
  providedIn: 'root',
})
export class LocalizeService {
  constructor(private readonly internationalizationService: InternationalizationService) {}

  readonly activeLanguage$: Observable<LanguageCode> = this.internationalizationService.activeLanguage$;

  localize(str: LocalizedString): Observable<string> {
    return this.activeLanguage$.pipe(
      map((lang) => {
        return this.getLocalizedValue(str, lang);
      }),
    );
  }

  localizeOnce(str: LocalizedString, lang = this.internationalizationService.getActiveLang()): string {
    return this.getLocalizedValue(str, lang);
  }

  private getLocalizedValue(str: LocalizedString, lang: LanguageCode): string {
    return str[lang] ?? str[DEFAULT_LANGUAGE] ?? ''; // TODO decide what to fall back on
  }
}
