import { DestroyRef, Inject, Injectable, Injector, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { mapToVoid } from '@examdojo/core/rxjs';
import { isNullish } from '@examdojo/util/nullish';
import { keys } from '@examdojo/util/object-utils';
import { BehaviorSubject, debounceTime, distinctUntilChanged, first, map, merge, Observable, Subject, tap } from 'rxjs';
import { FEATURE_FLAGS, FeatureFlag, FeatureFlags, ResolveFeatureFlagValue } from './feature-flag.injection-token';
import { PosthogFeatureFlagService } from './posthog-feature-flag.service';

export interface ExamdojoWindowFeature {
  enable: (flag: string) => void;
  disable: (flag: string) => void;
  list: () => void;
  reset: () => void;
}

interface ExamdojoWindow {
  feature: ExamdojoWindowFeature;
}

const setFlag$$ = new Subject<{ flag: FeatureFlag; enabled: boolean }>();
const list$$ = new Subject<void>();
const reset$$ = new Subject<void>();

(window as unknown as { examdojo: ExamdojoWindow }).examdojo = {
  ...((window as unknown as { examdojo: ExamdojoWindow }).examdojo || {}),
  feature: {
    enable: (flag) => setFlag$$.next({ flag, enabled: true }),
    disable: (flag) => setFlag$$.next({ flag, enabled: false }),
    list: () => list$$.next(),
    reset: () => reset$$.next(),
  },
};

const FEATURE_FLAG_STORAGE_KEY = `examdojo_feature_flags`;

@Injectable({ providedIn: 'root' })
export class FeatureFlagService<
  Flags extends FeatureFlags = FeatureFlags,
  ResolvedFlags extends ResolveFeatureFlagValue<Flags> = ResolveFeatureFlagValue<Flags>,
  FlagKey extends keyof Flags = keyof Flags,
> {
  constructor(
    @Inject(FEATURE_FLAGS) private readonly featureFlags: Flags,
    private readonly injector: Injector,
    private readonly posthogFeatureFlagService: PosthogFeatureFlagService,
    private readonly destroyRef: DestroyRef,
  ) {
    this.initializeListeners();

    merge(
      this.posthogFeatureFlagService.isInitialized$.pipe(
        first(Boolean),
        tap(() => {
          this.initialized$$.next(true);
        }),
      ),
      this.changeTrigger$.pipe(
        tap(() => {
          const allFlags = this.getAll();
          this.allFlags.set(allFlags);
        }),
      ),
    )
      .pipe(takeUntilDestroyed())
      .subscribe();
  }

  private readonly initialized$$ = new BehaviorSubject(false);
  readonly initialized$ = this.initialized$$.asObservable();

  private readonly defaultFeatureFlags = Object.entries(this.featureFlags)
    .map(([flag, valueOrCallback]) => ({
      flag,
      value: typeof valueOrCallback === 'function' ? valueOrCallback(this.injector) : valueOrCallback,
    }))
    .reduce((flags, { flag, value }) => {
      if (isNullish(flags[flag as FlagKey])) {
        flags[flag as FlagKey] = value;
      }
      return flags;
    }, {} as ResolvedFlags);

  private readonly locallyOverwrittenFlags$$: BehaviorSubject<Partial<ResolvedFlags>> = new BehaviorSubject(
    (safeJSONParse(localStorage.getItem(FEATURE_FLAG_STORAGE_KEY)) as Partial<ResolvedFlags>) || {},
  );
  private readonly changeTrigger$ = merge(this.initialized$, this.locallyOverwrittenFlags$$).pipe(
    debounceTime(0),
    mapToVoid(),
  );

  private readonly allFlags = signal<ResolvedFlags>(this.getAll());

  private readonly flags$$ = toObservable(this.allFlags).pipe(distinctUntilChanged());

  select<K extends keyof Flags>(flag: K): Observable<ResolvedFlags[K] | false> {
    return this.flags$$.pipe(
      map((allFlags) => allFlags[flag] ?? false),
      distinctUntilChanged(),
    );
  }

  get<K extends FlagKey>(flag: K): ResolvedFlags[K] | false {
    return this.allFlags()[flag] ?? false;
  }

  getAll(): ResolvedFlags {
    const posthogFeatureFlags = keys(this.featureFlags).reduce((flags, flag) => {
      const value = this.posthogFeatureFlagService.isFeatureEnabled(flag as string);
      if (isNullish(value)) {
        return flags;
      }
      return { ...flags, [flag]: value };
    }, {} as ResolvedFlags);

    return {
      ...this.defaultFeatureFlags,
      ...posthogFeatureFlags,
      ...this.locallyOverwrittenFlags$$.value,
    };
  }

  private initializeListeners() {
    setFlag$$
      .pipe(
        tap(({ flag, enabled }) => this.set(flag as FlagKey, enabled)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    list$$
      .pipe(
        tap(() =>
          Object.keys(this.allFlags())
            .map((flag) => ({ flag, value: this.get(flag as FlagKey) as boolean }))
            .sort(({ flag: flagA, value: valueA }, { flag: flagB, value: valueB }) => {
              // Sort list by enabled first, then by flag name
              if (valueA === valueB) {
                return flagA.localeCompare(flagB);
              }

              return valueA ? -1 : 1;
            })
            .forEach(({ flag, value }) =>
              this.print(`🏁 Flag "%c${flag}" %cis ${value ? '%cenabled ✅' : '%cdisabled 🚫'}`, value),
            ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();

    reset$$
      .pipe(
        tap(() => this.reset()),
        debounceTime(0),
        tap(() => this.print(`🏁 Flags have been %creset%c%c`)),
        tap(() => list$$.next()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  private set(flag: FlagKey, enabled: boolean) {
    const flags = this.locallyOverwrittenFlags$$.value;
    flags[flag] = enabled as ResolvedFlags[FlagKey];
    this.persist(flag, enabled);

    this.print(`🏁 Flag "%c${flag as string}" %cis ${enabled ? '%cenabled ✅' : '%cdisabled 🚫'}`, enabled);
  }

  private persist(flag: FlagKey, enabled: boolean) {
    const existingPersistedFlags = safeJSONParse(localStorage.getItem(FEATURE_FLAG_STORAGE_KEY)) || {};
    existingPersistedFlags[flag as string] = enabled;

    localStorage.setItem(FEATURE_FLAG_STORAGE_KEY, JSON.stringify(existingPersistedFlags));
    this.locallyOverwrittenFlags$$.next(existingPersistedFlags as Partial<ResolvedFlags>);
  }

  private reset() {
    localStorage.removeItem(FEATURE_FLAG_STORAGE_KEY);
    this.locallyOverwrittenFlags$$.next({});
  }

  private print(log: string, value?: boolean) {
    console.log(log, 'font-weight: bold', '', `font-weight: bold; color: ${value ? 'green' : 'red'}`);
  }
}

function safeJSONParse(str?: string | null): Record<string, unknown> | null {
  try {
    return JSON.parse(str || '{}');
  } catch (err) {
    return null;
  }
}
