import { Injectable } from '@angular/core';
import { SupabaseSelectQueryConfig } from '@examdojo/core/supabase';
import { from, map, Observable } from 'rxjs';
import { isNotNullish } from '@examdojo/core/util/nullish';
import { SchemaName, TableInsertModel, TableName, TableUpdateModel } from './database.types';
import { ExamdojoSupabaseService } from './supabase.service';

@Injectable()
export abstract class SupabaseBaseHttpService<T extends TableName<S>, S extends SchemaName = 'public'> {
  constructor(protected readonly supabase: ExamdojoSupabaseService) {}

  abstract schema: S;
  abstract tableName: T;
  readonly storage_bucket: string = '';

  fetch(itemId: number) {
    return from(
      this.supabase.client
        .schema(this.schema)
        .from(this.tableName)
        .select()
        .eq('id', itemId)
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  createSelectQuery(selectQueryConfig: SupabaseSelectQueryConfig = {}) {
    const query = this.supabase.createSelectQuery(this.schema, this.tableName, selectQueryConfig);

    return from(query).pipe(
      map(({ data, count }) => ({
        data: data!,
        totalCount: count!,
      })),
    );
  }

  create(item: TableInsertModel<T, S>) {
    return from(
      this.supabase.client
        .schema(this.schema)
        .from(this.tableName)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .insert(item as any)
        .select()
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  update(itemId: number, item: TableUpdateModel<T, S>) {
    return from(
      this.supabase.client
        .schema(this.schema)
        .from(this.tableName)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .update(item as any)
        .eq('id', itemId)
        .select()
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  delete(itemId: number): Observable<void> {
    return from(
      this.supabase.client
        .schema(this.schema)
        .from(this.tableName)
        .delete()
        .eq('id', itemId)
        .throwOnError()
        .then((response) => response.data!),
    );
  }

  getPublicUrl(imageId: string) {
    return this.supabase.getPublicUrl(this.storage_bucket, imageId);
  }

  getSignedUrl(imageId: string) {
    return this.supabase.getSignedUrlForFile(this.storage_bucket, imageId);
  }

  getSignedUrls(imageIds: string[]) {
    return this.supabase.getSignedUrlsForFiles(this.storage_bucket, imageIds);
  }

  uploadImage(image: File, name?: string) {
    return this.supabase.uploadFile(this.storage_bucket, image, image.type, name);
  }

  removeImage(imageId: string) {
    return this.supabase.removeFiles(this.storage_bucket, imageId);
  }

  async getSignedUrlForImages(imageNames: Array<string | null>) {
    const data = await this.getSignedUrls(imageNames.filter(isNotNullish));

    return data.reduce<Record<string, string | null>>((acc, item) => {
      return {
        ...acc,
        ...(item.path ? { [item.path]: item.error ? null : item.signedUrl } : {}),
      };
    }, {});
  }
}
