import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IonHeader, IonToolbar } from '@ionic/angular/standalone';

@Component({
  selector: 'dojo-top-header',
  template: `
    <ion-header class="ion-header">
      <ion-toolbar class="!px-6">
        <div class="flex items-center justify-between py-4">
          <a [routerLink]="'/'" class="flex"
            ><img src="assets/images/logo/logo-color-horizontal.svg" alt="Exam dojo"
          /></a>
          <ng-content></ng-content>
        </div>
      </ion-toolbar>
    </ion-header>
  `,
  styles: [
    `
      .ion-header {
        box-shadow: none;
        border: 0;

        ion-toolbar {
          --background: white;
          --padding-top: 0;
          --padding-bottom: 0;
          --border-width: 0;
        }
      }
    `,
  ],
  standalone: true,
  imports: [RouterLink, IonHeader, IonToolbar],
  host: { class: 'block bg-white' },
})
export class TopHeaderComponent {}
