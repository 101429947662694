export function getErrorMessage(error: unknown): string {
  // errors returned by the web worker are transformed to objects
  // so it will work for a broader amount of cases than `error instanceof Error`
  if (error instanceof Object && 'message' in error && typeof error.message === 'string') {
    return error.message;
  }

  if (typeof error === 'object' && error !== null) {
    console.error('An invalid error was thrown:', error);
  }

  if (error === null || typeof error === 'undefined') {
    return '';
  }

  // We could try using JSON.stringify,
  // but it's not guaranteed that the error will be serializable or meaningful.
  return String(error);
}
