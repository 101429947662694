import {
  ChangeDetectorRef,
  Directive,
  EmbeddedViewRef,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ɵstringify as stringify,
} from '@angular/core';
import { isNullish } from '@examdojo/util/nullish';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { FeatureFlag } from './feature-flag.injection-token';
import { FeatureFlagService } from './feature-flag.service';

@UntilDestroy()
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: '[ngIfFeatureFlag]', standalone: true })
export class FeatureFlagDirective implements OnInit {
  constructor(
    private readonly _viewContainer: ViewContainerRef,
    private readonly featureFlagService: FeatureFlagService<Record<string, boolean>>,
    templateRef: TemplateRef<NgIfFeatureFlagContext>,
  ) {
    this._thenTemplateRef = templateRef;
  }

  @Input() ngIfFeatureFlag: FeatureFlag | null = null;

  /**
   * A template to show if the condition expression evaluates to true.
   */
  @Input()
  set ngIfFeatureFlagThen(templateRef: TemplateRef<NgIfFeatureFlagContext> | null) {
    assertTemplate('ngIfFeatureFlagThen', templateRef);
    this._thenTemplateRef = templateRef;
    this._thenViewRef = null; // clear previous view if any.
    this._updateView();
  }

  /**
   * A template to show if the condition expression evaluates to false.
   */
  @Input()
  set ngIfFeatureFlagElse(templateRef: TemplateRef<NgIfFeatureFlagContext> | null) {
    assertTemplate('ngIfFeatureFlagElse', templateRef);
    this._elseTemplateRef = templateRef;
    this._elseViewRef = null; // clear previous view if any.
    this._updateView();
  }

  private readonly _context: NgIfFeatureFlagContext = new NgIfFeatureFlagContext();
  private _thenTemplateRef: TemplateRef<NgIfFeatureFlagContext> | null = null;
  private _elseTemplateRef: TemplateRef<NgIfFeatureFlagContext> | null = null;
  private _thenViewRef: EmbeddedViewRef<NgIfFeatureFlagContext> | null = null;
  private _elseViewRef: EmbeddedViewRef<NgIfFeatureFlagContext> | null = null;

  ngOnInit() {
    if (isNullish(this.ngIfFeatureFlag)) {
      return;
    }

    this.featureFlagService
      .select(this.ngIfFeatureFlag)
      .pipe(
        tap((enabled) => {
          this._context.$implicit = enabled;
          this._updateView();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private _updateView() {
    const featureFlagEnabled = this._context.$implicit;

    if (featureFlagEnabled) {
      if (!this._thenViewRef) {
        this._viewContainer.clear();
        this._elseViewRef = null;
        if (this._thenTemplateRef) {
          this._thenViewRef = this._viewContainer.createEmbeddedView(this._thenTemplateRef, this._context);
          this._viewContainer.injector.get(ChangeDetectorRef).detectChanges();
        }
      }
    } else {
      if (!this._elseViewRef) {
        this._viewContainer.clear();
        this._thenViewRef = null;
        if (this._elseTemplateRef) {
          this._elseViewRef = this._viewContainer.createEmbeddedView(this._elseTemplateRef, this._context);
          this._viewContainer.injector.get(ChangeDetectorRef).detectChanges();
        }
      }
    }
  }
}

export class NgIfFeatureFlagContext {
  public $implicit = false;
}

function assertTemplate(property: string, templateRef: TemplateRef<unknown> | null): void {
  const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
  if (!isTemplateRefOrNull) {
    throw new Error(`${property} must be a TemplateRef, but received '${stringify(templateRef)}'.`);
  }
}
