import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { HashMap } from '@jsverse/transloco/lib/types';
import { filter, Observable } from 'rxjs';
import { SUPPORTED_LANGUAGES, SupportedLanguage } from './supported-languages';

@Injectable({ providedIn: 'root' })
export class InternationalizationService {
  constructor(private readonly translocoService: TranslocoService) {}

  readonly activeLanguage$ = this.translocoService.langChanges$.pipe(
    filter((lang): lang is SupportedLanguage => SUPPORTED_LANGUAGES.includes(lang as SupportedLanguage)),
  );

  setActiveLang(lang: SupportedLanguage) {
    this.translocoService.setActiveLang(lang);
  }

  getActiveLang() {
    return this.translocoService.getActiveLang() as SupportedLanguage;
  }

  translate(key: string, params?: HashMap, lang?: SupportedLanguage) {
    return this.translocoService.translate(key, params, lang);
  }

  selectTranslate(key: string, params?: HashMap, lang?: SupportedLanguage): Observable<string> {
    return this.translocoService.selectTranslate(key, params, lang);
  }
}
