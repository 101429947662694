import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import xorWith from 'lodash/xorWith';

export type AreArraysEqualParams = { orderMatters?: boolean } | undefined;

/**
 * compares 2 arrays
 * @example
 * const arr1 = [{a: 1, b: 2}, {c: 3, d: 4}];
 * const arr2 = [{d: 4, c: 3}, {b: 2, a: 1}];
 * areArraysEqual(arr1, arr2, { orderMatters: false }); // true
 *
 * const arr1 = [1,2];
 * const arr2 = [1,2];
 * areArraysEqual(arr1, arr2, { orderMatters: true }); // true
 * areArraysEqual(arr1, arr2, { orderMatters: false }); // true
 *
 * const arr1 = [1,2];
 * const arr2 = [2,1];
 * areArraysEqual(arr1, arr2, { orderMatters: true }); // false
 * areArraysEqual(arr1, arr2, { orderMatters: false }); // true
 * @param params
 * orderMatters == true -> the order of the items in the array should also equal;
 * orderMatters == false (default) -> compares arrays regardless the item order
 */
export function areArraysEqual<T>(a: T[], b: T[], params?: AreArraysEqualParams): boolean {
  if (a.length !== b.length) {
    return false;
  }

  const orderMatters = params?.orderMatters ?? true;
  if (orderMatters) {
    return isEqual(a, b);
  }

  return isEmpty(xorWith(a, b, isEqual));
}
