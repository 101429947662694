import { Injectable } from '@angular/core';
import { UserHttpModel } from '@examdojo/models/user';
import { ExamdojoSupabaseService, TableUpdateModel } from '@examdojo/supabase';
import { from, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserHttpService {
  constructor(private readonly supabase: ExamdojoSupabaseService) {}

  fetchCurrentUserRole() {
    return from(
      this.supabase.client
        .rpc('get_current_user_role')
        .throwOnError()
        .then((res) => res.data),
    );
  }

  fetchAll() {
    return from(
      this.supabase.client
        .from('users')
        .select()
        .throwOnError()
        .then((res) => res.data!),
    );
  }

  fetchActiveUserProfile() {
    return from(
      this.supabase.client
        .rpc('get_current_user_profile')
        .throwOnError()
        .then((res) => res.data!),
    );
  }

  update(id: UserHttpModel['id'], user: TableUpdateModel<'users'>): Observable<UserHttpModel> {
    return from(
      this.supabase.client
        .from('users')
        .update({
          ...user,
        })
        .eq('id', id)
        .select()
        .single()
        .throwOnError()
        .then((response) => response.data!),
    );
  }
}
