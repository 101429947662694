import { TrackByFunction } from '@angular/core';

export function getTrackByIdentityFn<T>(idProjectionFn: (item: T) => unknown): TrackByFunction<T> {
  return (index, item: T) => (item ? idProjectionFn(item) || index : index);
}
export function getTrackByFn<T extends object>(key: keyof T): TrackByFunction<T> {
  return (index, item: T) => (item ? item[key] : index);
}
export function getTrackByIndexFn<T extends object>(): TrackByFunction<T> {
  return (index) => index;
}

export const trackByIndex: TrackByFunction<unknown> = (index: number) => index;
export const trackByValue = <T>(_index: number, value: T) => value;
