import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  AngularMaterialAnimationFunction,
  AnimationDelay,
  AnimationDuration,
  AnimationOptions,
  getAnimationTimingMs,
} from './animations.model';

export const AppearDisappearAnimationFunction = AngularMaterialAnimationFunction;

/**
 * Will animate the element in and out using just opacity.
 * Apply to an HTML element with an *ngIf on it
 */
export const getAnimationAppearDisappear = (opt?: AnimationOptions) => {
  const {
    triggerName = 'appearDisappear',
    enter = AnimationDuration.SemiLong,
    leave = AnimationDuration.Short,
    enterDelay = AnimationDuration.None,
    leaveDelay = AnimationDuration.None,
  } = opt || {};

  return trigger(triggerName, [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(
        `${getAnimationTimingMs(enter)} ${getAnimationTimingMs(enterDelay)} ${AppearDisappearAnimationFunction}`,
        style({ opacity: '*' }),
      ),
    ]),
    transition(':leave', [
      style({ opacity: '*' }),
      animate(
        `${getAnimationTimingMs(leave)} ${getAnimationTimingMs(leaveDelay)} ${AppearDisappearAnimationFunction}`,
        style({ opacity: 0 }),
      ),
    ]),
  ]);
};

/**
 * Will animate the element in and out using opacity and height.
 * Apply to an HTML element with an *ngIf on it
 */
export const getAnimationAppearDisappearWithHeight = (opt?: AnimationOptions) => {
  const {
    triggerName = 'appearDisappearWithHeight',
    enter = AnimationDuration.Short,
    leave = AnimationDuration.Short,
    enterDelay = '0ms',
    leaveDelay = '0ms',
  } = opt || {};

  return trigger(triggerName, [
    transition(':enter', [
      style({ opacity: 0, height: 0 }),
      animate(
        `${getAnimationTimingMs(enter)} ${getAnimationTimingMs(enterDelay)} ${AppearDisappearAnimationFunction}`,
        style({ opacity: '*', height: '*' }),
      ),
    ]),
    transition(
      ':leave',
      [
        style({ opacity: '*', height: '*' }),
        animate(
          `{{leaveTimeParam}}ms ${getAnimationTimingMs(leaveDelay)} ${AppearDisappearAnimationFunction}`,
          style({ opacity: 0, height: 0 }),
        ),
      ],
      { params: { leaveTimeParam: leave } },
    ),
  ]);
};

/**
 * Will animate the element in and out using opacity, height and margin.
 * Apply to an HTML element with an *ngIf on it
 */
export const getAnimationAppearDisappearWithHeightMargin = (opt?: AnimationOptions) => {
  const {
    triggerName = 'appearDisappearWithHeightMargin',
    enter = AnimationDuration.Short,
    leave = AnimationDuration.Short,
    enterDelay = '0ms',
    leaveDelay = '0ms',
  } = opt || {};

  return trigger(triggerName, [
    transition(':enter', [
      style({ opacity: 0, height: 0, 'margin-top': 0, 'margin-bottom': 0 }),
      animate(
        `${getAnimationTimingMs(enter)} ${getAnimationTimingMs(enterDelay)} ${AppearDisappearAnimationFunction}`,
        style({ opacity: '*', height: '*', 'margin-top': '*', 'margin-bottom': '*' }),
      ),
    ]),
    transition(':leave', [
      style({ opacity: '*', height: '*', 'margin-top': '*', 'margin-bottom': '*' }),
      animate(
        `${getAnimationTimingMs(leave)} ${getAnimationTimingMs(leaveDelay)} ${AppearDisappearAnimationFunction}`,
        style({ opacity: 0, height: 0, 'margin-top': 0, 'margin-bottom': 0 }),
      ),
    ]),
  ]);
};

export const getAnimationAppearOnTrigger = (opt?: AnimationOptions) => {
  const {
    triggerName = 'appearOnTrigger',
    enter = AnimationDuration.SemiLong,
    enterDelay = AnimationDelay.None,
  } = opt || {};

  return trigger(triggerName, [
    state('true', style({ opacity: 1 })),
    state('false', style({ opacity: 0 })),
    transition('false => true', [
      animate(`${getAnimationTimingMs(enter)} ${getAnimationTimingMs(enterDelay)} ${AppearDisappearAnimationFunction}`),
    ]),
  ]);
};
