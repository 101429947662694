import { CheckForExcess, CheckForMissing } from '@examdojo/core/typescript';
import { Enum } from '@examdojo/supabase';

export type UserRole = Enum<'user_role'>;

export const USER_ROLE_ORDER = ['STUDENT', 'CONTENT_CREATOR', 'ADMIN'] satisfies UserRole[];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type UserRoleOrderMissingCheck = CheckForMissing<typeof USER_ROLE_ORDER, UserRole>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type ExcessCheck = CheckForExcess<typeof USER_ROLE_ORDER, UserRole>;
