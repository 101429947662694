import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { AppStore } from './app.store';

@Injectable({ providedIn: 'root' })
export class AppInitializedGuard {
  constructor(private readonly appStore: AppStore) {}

  canActivate(): Observable<boolean> {
    this.appStore.clearLoading();

    return of(true);
  }
}
