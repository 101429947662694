import { IS_PROD_BUILD } from '@examdojo/core/environment';
import { firstValueFrom, Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

if (!IS_PROD_BUILD) {
  // HACK:
  // This is a compile-time hack to figure out
  // that `@examdojo/environment` is being included in the web-worker
  //
  // In the tsconfig.worker.json we deliberately have set a lower `lib` (2019),
  // which doesn't support "String.replaceAll"
  const _x = ''.replaceAll('', '');
  //
  // So if you're running into an error due to this
  // you most probably have mistakenly included `@examdojo/util` or `@examdojo/environment`
  // in a file that is indirectly referenced by one of the web workers.
  //
}

export function takePromise<T>(obs$: Observable<T>, timeoutMs?: number): Promise<T> {
  // A super long-lasting takePromise without a timeout leads to difficult to debug issues
  if (!IS_PROD_BUILD) {
    // Initialize error here for better stack trace
    const timeoutError = new Error(`[takePromise]: timeout error occurred`);
    return firstValueFrom(
      obs$.pipe(
        // if this timeout is too small for you, feel free to increase
        timeout(timeoutMs ?? 30000),
        catchError((err: unknown) => {
          if (err instanceof TimeoutError) {
            return throwError(() => timeoutError);
          }

          return throwError(() => err);
        }),
      ),
    );
  }

  return firstValueFrom(obs$);
}
