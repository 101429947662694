import { A11yModule } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DirtyInvalidErrorStateMatcher } from '@examdojo/angular/forms';
import { connectState, InputObservable } from '@examdojo/angular/util';
import { CopyToClipboardComponent } from '@examdojo/core/copy-to-clipboard';
import { ErrorMessages, FormFieldLabelDirective, selectFirstErrorMessage } from '@examdojo/core/form';
import { InfoIconComponent } from '@examdojo/core/info-icon';
import { ReactiveLifecycleHooksDirective } from '@examdojo/core/reactive-lifecycle-hooks';
import { BehaviorSubject, delay, filter, map, Observable, switchMap, tap } from 'rxjs';
import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';

const INPUT_IMPORTS: Component['imports'] = [
  ReactiveFormsModule,
  MatFormFieldModule,
  MatInputModule,
  MatTooltipModule,
  InfoIconComponent,
  CopyToClipboardComponent,
  FormFieldLabelDirective,
  A11yModule,
  NgIf,
  NgTemplateOutlet,
  NgClass,
];
const errorStateMatcher = new DirtyInvalidErrorStateMatcher();

@UntilDestroy()
@Directive()
export class _InputComponent<T = unknown> extends ReactiveLifecycleHooksDirective {
  constructor() {
    super();

    this.ngAfterViewInit$
      .pipe(
        delay(0),
        filter(() => !!this.hasInitialFocus),
        tap(() => this.matInput.focus()),
        takeUntilDestroyed(),
      )
      .subscribe();
  }
  @ViewChild(MatInput) matInput!: MatInput;

  @Input()
  @InputObservable()
  label?: string | TemplateRef<unknown> | null;
  private readonly label$!: Observable<string | TemplateRef<unknown> | null>;

  @Input({ required: true }) formCtrl!: FormControl<T | null | undefined>;
  @Input() type: 'text' | 'password' | 'number' | 'textarea' | 'email' = 'text';
  @Input() hideErrors = false;
  @Input() errorMessages?: ErrorMessages;
  @Input() required = false;
  @Input() color?: MatFormField['color'];
  @Input() tooltipHint?: string | null;
  @Input() enableCopyToClipboard = false;
  @Input() compact?: boolean | null;
  @Input() upperCase?: boolean | null;
  @Input() hasInitialFocus?: boolean;
  @Input() fullWidthField = false;
  @Input() resizable = true;

  // HTMLInputElement properties
  @Input() placeholder?: HTMLInputElement['placeholder'];
  @Input() autocomplete?: HTMLInputElement['autocomplete'];
  @Input() readOnly?: HTMLInputElement['readOnly'];
  @Input() tabIndex?: HTMLInputElement['tabIndex'];
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() input = new EventEmitter<Event>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() keyup = new EventEmitter<KeyboardEvent>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur = new EventEmitter<FocusEvent>();
  // ---

  readonly errorStateMatcher = errorStateMatcher;

  private readonly tooltip$$ = new BehaviorSubject('');

  readonly state = connectState({
    labelTemplate: this.label$.pipe(
      map((label) => label instanceof TemplateRef),
      map((isTemplatRef) => (isTemplatRef ? (this.label as TemplateRef<unknown>) : null)),
    ),
    errorMessage: this.ngAfterViewInit$.pipe(
      map(() => this.formCtrl),
      switchMap((ctrl) => selectFirstErrorMessage(ctrl, this.errorMessages)),
    ),
    tooltip: this.tooltip$$,
  });

  setTooltip(message: string) {
    this.tooltip$$.next(message);
  }

  focus() {
    this.matInput.focus();
  }
}

@Component({
  selector: 'dojo-text-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [INPUT_IMPORTS],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextInputComponent extends _InputComponent<string> {
  @Input() override fullWidthField = false;
  @Input() override type: _InputComponent['type'] = 'text';
}

@Component({
  selector: 'dojo-number-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [INPUT_IMPORTS],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberInputComponent extends _InputComponent<number> {
  @Input() override fullWidthField = false;
  override readonly type: _InputComponent['type'] = 'number';
}
@Component({
  selector: 'dojo-textarea-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [INPUT_IMPORTS],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaInputComponent extends _InputComponent<string> {
  @Input() override fullWidthField = false;
  override readonly type: _InputComponent['type'] = 'textarea';
}
