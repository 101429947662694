import { JsonPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { BaseButton } from '@examdojo/core/button';
import { IonButton, IonSpinner } from '@ionic/angular/standalone';

export type ButtonExpand = IonButton['expand'];
export type ButtonFill = IonButton['fill'];
export type ButtonColor = IonButton['color'];

@Component({
  selector: 'dojo-button',
  standalone: true,
  imports: [IonButton, IonSpinner, JsonPipe],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  host: {
    '[attr.disabled]': 'this.disabled() ? true : undefined',
    '[style.pointer-events]': "this.disabled() || this.pending ? 'none' : undefined",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BaseButton, useExisting: ButtonComponent }],
})
export class ButtonComponent extends BaseButton {
  readonly expand = input<ButtonExpand>();
  readonly small = input<boolean>(false);
  readonly micro = input<boolean>(false);
  readonly danger = input<boolean>(false);
  readonly fill = input<ButtonFill>('solid');
  readonly color = input<ButtonColor>();

  readonly computedFill = computed(() => (this.danger() ? 'outline' : this.fill()));
}
