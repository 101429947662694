import { Injectable } from '@angular/core';
import { Store } from '@examdojo/state';
import { createStore, withProps } from '@ngneat/elf';

export interface AppStoreState {
  loading: boolean;
  status: {
    message: string;
  } | null;
}

export function createInitialState(): AppStoreState {
  return {
    loading: true,
    status: null,
  };
}

const appStore = createStore({ name: 'auth' }, withProps<AppStoreState>(createInitialState()));

@Injectable({ providedIn: 'root' })
export class AppStore extends Store<AppStoreState> {
  constructor() {
    super(appStore);
  }

  setLoading() {
    this.store.update((state) => ({
      ...state,
      loading: true,
    }));
  }

  clearLoading() {
    this.store.update((state) => ({
      ...state,
      loading: false,
    }));
  }
}
