import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { connectState, InputObservable } from '@examdojo/angular/util';
import { Icon, IconModule } from '@examdojo/core/icon';
import { combineLatest, map, Observable } from 'rxjs';

export type IconButtonSize = 'compact' | 'large';
@Component({
  selector: 'y42-icon-button',
  standalone: true,
  imports: [MatButtonModule, MatProgressSpinnerModule, IconModule, MatTooltipModule, NgClass],
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  @Input({ required: true }) icon!: Icon;
  @Input() color?: ThemePalette;
  @Input() type: HTMLButtonElement['type'] = 'button';
  @Input() classes?: string | null;
  @Input() prefix: IconPrefix = 'fal';
  @Input() iconClass?: string;

  @Input()
  @InputObservable()
  size?: IconButtonSize;
  size$!: Observable<IconButtonSize>;

  @Input()
  @InputObservable()
  iconSize?: number | string;
  iconSize$!: Observable<number | string>;

  @Input()
  disabled?: boolean | null;

  @HostBinding('attr.disabled')
  get disabledAttr() {
    return this.disabled ? true : undefined;
  }

  @HostBinding('style.pointer-events')
  get pointerEvents() {
    return this.disabled ? 'none' : undefined;
  }

  readonly ICON_DEFAULT_SIZE = 16;
  readonly ICON_COMPACT_SIZE = 12;

  readonly state = connectState({
    iconSize: combineLatest([this.size$, this.iconSize$]).pipe(
      map(([size, iconSize]) => {
        if (iconSize) {
          return iconSize;
        } else if (size === 'compact') {
          return this.ICON_COMPACT_SIZE;
        } else {
          return this.ICON_DEFAULT_SIZE;
        }
      }),
    ),
  });
}
