<y42-icon
  [icon]="icon ?? 'circle-info'"
  [mtxTooltip]="tooltipTmpl"
  [mtxTooltipClass]="'mat-mdc-tooltip'"
  [size]="size"
  [ngClass]="state.iconColor"
  class="p-px"
/>
<ng-template #tooltipTmpl>
  @if (state.tooltipTmplMessage) {
    <ng-container *ngTemplateOutlet="state.tooltipTmplMessage"></ng-container>
  } @else if (message) {
    <div [innerHTML]="message"></div>
  }
</ng-template>
