import { bootstrapApplication } from '@angular/platform-browser';
import { LicenseManager } from 'ag-grid-enterprise';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

LicenseManager.setLicenseKey(
  'CompanyName=Datos-Intelligence GmbH,LicensedApplication=Datos,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-026970,ExpiryDate=15_May_2023_[v2]_MTY4NDEwNTIwMDAwMA==16880adeaae7008eb4e97ef84502e8cd',
);

bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
