<ion-header class="px-3">
  <ion-toolbar>
    <div slot="start" class="flex">
      <y42-button (click)="closeModal()">{{ 'cancel' | transloco }}</y42-button>
    </div>

    <ion-title class="font-primary text-title-m text-typography-primary text-center font-medium">
      {{ 'select_language' | transloco }}
    </ion-title>

    <div slot="end">
      <y42-button color="primary" (click)="save()">{{ 'save' | transloco }}</y42-button>
    </div>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="h-full p-4">
    <div class="flex items-center justify-between gap-4">
      @for (language of state.languages; track language.code; let idx = $index) {
        <div class="flex grow justify-center">
          <span
            class="cursor-pointer p-4 hover:bg-gray-500"
            [class.selected]="language.isSelected"
            (click)="selectLanguage(language.code)"
          >
            {{ language.label }}
          </span>
        </div>
      }
    </div>
  </div>
</ion-content>
