import { OrArray } from '@ngneat/elf';
import { isNullish } from './nullish';

export function ensureArray<T>(source: OrArray<T>, nullishAsEmptyArray = true) {
  if (Array.isArray(source)) {
    return source;
  }

  if (nullishAsEmptyArray && isNullish(source)) {
    return [];
  }

  return [source];
}

export { OrArray };
