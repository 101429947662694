<y42-password-input
  [label]="label"
  [formCtrl]="passwordComponent.passwordFormControl"
  data-test="change-password-input-new-password"
  [hideErrors]="state.hasPasswordStrengthErrors"
  autocomplete="new-password"
  [errorMessages]="state.errorMessages"
/>

<mat-password-strength
  #passwordComponent
  [warnThreshold]="60"
  [accentThreshold]="80"
  [password]="passwordComponent.passwordFormControl.value"
  class="custom-password"
  [class.hide-custom-password]="!state.hasPasswordStrengthErrors || state.hasRequiredError"
  [class.strong-password]="state.passwordStrengthScore === 80"
  (onStrengthChanged)="setPasswordStrength($event)"
/>

@if (state.hasPasswordStrengthErrors) {
  <div class="custom-password-strength-wrapper" @appearDisappearWithHeight>
    <mat-password-strength-info
      class="custom-password-strength"
      [@.disabled]="true"
      [passwordComponent]="passwordComponent"
      [upperCaseCriteriaMsg]="'An uppercase letter'"
      [lowerCaseCriteriaMsg]="'A lowercase letter'"
      [minCharsCriteriaMsg]="'8 characters minimum'"
      [digitsCriteriaMsg]="'A number'"
      [specialCharsCriteriaMsg]="'A symbol'"
      [matIconDone]="'check'"
      [matIconError]="'close'"
    />
    <span class="score-info-label">{{ state.passwordStrengthScoreLabel }}</span>
  </div>
}
