import { NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'y42-logo-loader',
  standalone: true,
  imports: [NgStyle],
  templateUrl: './logo-loader.component.html',
  styleUrls: ['./logo-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoLoaderComponent {
  @Input() widthInPixel = 30;
}
