import { AfterViewInit, Directive, ElementRef, Input, Optional, Renderer2 } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, map, startWith, tap } from 'rxjs';
/**
 * Use this directive on element with [formCtrl] to specify form control that needs to be highlighted with (Optional) label in case if form control is optional.
 */
@Directive({
  standalone: true,
  selector: '[y42FormField]',
})
export class FormFieldDirective<T = unknown> {
  @Input() formCtrl: FormControl<T> | undefined;
}

@UntilDestroy()
@Directive({
  standalone: true,
  selector: '[y42FormFieldLabel]',
})
export class FormFieldLabelDirective implements AfterViewInit {
  constructor(
    @Optional() private readonly formField: FormFieldDirective | null,
    private readonly renderer: Renderer2,
    private readonly elRef: ElementRef,
  ) {}

  ngAfterViewInit() {
    if (!this.formField || !this.formField.formCtrl) {
      return;
    }

    this.setLabelOptionalClassOnStatusChange(this.formField.formCtrl).pipe(untilDestroyed(this)).subscribe();
  }

  private setLabelOptionalClassOnStatusChange(formControl: FormControl) {
    return formControl.statusChanges.pipe(
      startWith(formControl.status),
      map(() => formControl.hasValidator(Validators.required)),
      distinctUntilChanged(),
      tap((isFieldRequired) => {
        if (!isFieldRequired) {
          this.renderer.addClass(this.elRef.nativeElement, 'custom-optional');
        } else {
          this.renderer.removeClass(this.elRef.nativeElement, 'custom-optional');
        }
      }),
    );
  }
}
