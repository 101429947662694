import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Directive,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatFormFieldModule } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DirtyInvalidErrorStateMatcher } from '@examdojo/angular/forms';
import { connectState, InputObservable } from '@examdojo/angular/util';
import { FormFieldLabelDirective, selectFirstErrorMessage } from '@examdojo/core/form';
import { InfoIconComponent } from '@examdojo/core/info-icon';
import { ReactiveLifecycleHooksDirective } from '@examdojo/core/reactive-lifecycle-hooks';
import { isNullish } from '@examdojo/core/util/nullish';
import { ensureArray } from '@examdojo/util/ensure-array';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, map, Observable, of, shareReplay, startWith, switchMap } from 'rxjs';

const errorStateMatcher = new DirtyInvalidErrorStateMatcher();

export interface SelectOption<T extends string | number = string | number> {
  label?: string;
  value?: T;
}

export const trackBySelectItemValue: TrackByFunction<SelectOption> = (index, item) => item.value ?? index;

export type SelectOptionValueFn<T extends object = SelectOption> = (item: T) => unknown;
export type SelectOptionLabelFn<T extends object = SelectOption> = (item: T) => string;
export type SelectOptionDisabledFn<T extends object = SelectOption> = (item: T) => boolean;

export interface SelectOptionType<T = SelectOption> {
  $implicit: T;
}

@Directive({
  selector: 'ng-template[y42SelectOption]',
  standalone: true,
})
export class SelectOptionDirective<T extends object = SelectOption & SelectOptionType> {
  constructor(public readonly templateRef: TemplateRef<T | null>) {}

  @Input() y42SelectOption!: T;

  static ngTemplateContextGuard<T extends object>(dir: SelectOptionDirective<T>, ctx: unknown): ctx is T {
    return true;
  }
}

@Directive({
  selector: 'ng-template[y42SelectTrigger]',
  standalone: true,
})
export class SelectTriggerDirective<T extends object = SelectOption & SelectOptionType> {
  constructor(public readonly templateRef: TemplateRef<T | null>) {}

  @Input() y42SelectTrigger?: T;

  static ngTemplateContextGuard<T extends object>(dir: SelectTriggerDirective<T>, ctx: unknown): ctx is T {
    return true;
  }
}

const SELECT_IMPORTS: Component['imports'] = [
  NgIf,
  NgFor,
  NgClass,
  NgTemplateOutlet,
  ReactiveFormsModule,
  MatFormFieldModule,
  MatSelectModule,
  MatTooltipModule,
  MatInput,
  InfoIconComponent,
  FormFieldLabelDirective,
];

@UntilDestroy()
@Directive()
export abstract class _SelectComponent<
  Option extends object = SelectOption,
  Value = unknown,
> extends ReactiveLifecycleHooksDirective {
  abstract readonly _multiple: boolean;

  @ViewChild(MatSelect) matSelect!: MatSelect;

  @Input() @InputObservable() options?: Option[] | null;
  readonly options$!: Observable<Option[] | null | undefined>;

  @Input() @InputObservable() optionValue?: keyof Option | SelectOptionValueFn<Option>;
  readonly optionValue$!: Observable<keyof Option | SelectOptionValueFn<Option> | undefined>;

  @Input() @InputObservable() optionLabel?: keyof Option | SelectOptionLabelFn<Option>;
  readonly optionLabel$!: Observable<keyof Option | SelectOptionLabelFn<Option> | undefined>;

  @Input() @InputObservable() optionDisabled?: keyof Option | SelectOptionDisabledFn<Option>;
  readonly optionDisabled$!: Observable<keyof Option | SelectOptionDisabledFn<Option> | undefined>;

  @Input() @InputObservable() label?: string | TemplateRef<unknown> | null;
  private readonly label$!: Observable<string | TemplateRef<unknown> | null>;

  @ContentChild(SelectOptionDirective) selectOptionDirective?: SelectOptionDirective;
  @ContentChild(SelectTriggerDirective) selectTriggerDirective?: SelectTriggerDirective;

  @Input() optionTemplate?: SelectOptionDirective['templateRef'];
  @Input() triggerTemplate?: SelectTriggerDirective['templateRef'];

  @Input({ required: true })
  @InputObservable()
  formCtrl!: FormControl<Value>;
  private readonly formCtrl$!: Observable<FormControl<Value> | undefined>;

  @Input() hideErrors = false;
  @Input() errorMessages?: Record<string, string>;
  @Input() required = false;
  @Input() color?: MatFormField['color'];
  @Input() tooltipHint?: string;
  @Input() compact? = false;
  @Input() micro? = false;
  @Input() borderless? = false;
  @Input() trackBy: TrackByFunction<Option & SelectOption> = trackBySelectItemValue;
  @Input() fullWidthField = false;
  @Input() nativeSelect = false;

  // MatSelect properties
  @Input() compareWith?: MatSelect['compareWith'];

  @Input() panelClass: MatSelect['panelClass'] = '';

  @Input() placeholder?: MatSelect['placeholder'];
  @Input() hideSingleSelectionIndicator?: MatSelect['hideSingleSelectionIndicator'] = true;
  // ---

  @Output() selectionChange = new EventEmitter<Value>();

  readonly errorStateMatcher = errorStateMatcher;

  private readonly tooltip$$ = new BehaviorSubject('');

  private readonly formCtrlValueChanges$ = this.formCtrl$.pipe(
    switchMap((formCtrl) => formCtrl?.valueChanges.pipe(startWith(formCtrl.value)) || of(undefined)),
  );

  private readonly mappedOptions$ = combineLatest([
    this.options$,
    this.optionValue$,
    this.optionLabel$,
    this.optionDisabled$,
  ]).pipe(
    map(([options, optionValue, optionLabel, optionDisabled]) => {
      return this.mapOptions(options, optionValue, optionLabel, optionDisabled);
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly state = connectState({
    labelTemplate: this.label$.pipe(
      map((label) => label instanceof TemplateRef),
      map((isTemplatRef) => (isTemplatRef ? (this.label as TemplateRef<unknown>) : null)),
    ),
    errorMessage: this.ngAfterViewInit$.pipe(
      map(() => this.formCtrl),
      switchMap((ctrl) => selectFirstErrorMessage(ctrl, this.errorMessages)),
    ),
    options: this.mappedOptions$,
    triggerTemplateImplicitContext: this.ngAfterViewInit$.pipe(
      switchMap(() => combineLatest([this.formCtrlValueChanges$, this.mappedOptions$])),
      map(([value, options]) => {
        if (!this.selectTriggerDirective || isNullish(value)) {
          return;
        }

        if (!this._multiple) {
          return options.find((option) => option.value === value)?.option;
        }

        const valueArr = ensureArray(value);

        return options
          .filter((option) => valueArr.find((valueItem) => valueItem === option.value))
          .map(({ option }) => option);
      }),
      startWith(undefined),
    ),
    tooltip: this.tooltip$$,
  });

  focus() {
    this.matSelect.focus();
  }

  setTooltip(message: string) {
    this.tooltip$$.next(message);
  }

  mapOptions(
    options: Option[] | null | undefined,
    optionValue: keyof Option | SelectOptionValueFn<Option> | undefined,
    optionLabel: keyof Option | SelectOptionLabelFn<Option> | undefined,
    optionDisabled: keyof Option | SelectOptionDisabledFn<Option> | undefined,
  ) {
    if (!options) {
      return [];
    }

    return options.map((option) => {
      const label: string =
        typeof optionLabel === 'function'
          ? optionLabel(option)
          : optionLabel
            ? String(option[optionLabel])
            : 'Missing "optionLabel"';
      const value =
        typeof optionValue === 'function' ? optionValue(option) : optionValue ? option[optionValue] : option;
      const disabled: boolean =
        typeof optionDisabled === 'function'
          ? optionDisabled(option)
          : optionDisabled
            ? Boolean(option[optionDisabled])
            : false;

      return {
        label,
        value,
        disabled,
        option,
      };
    });
  }
}

@UntilDestroy()
@Component({
  selector: 'y42-single-select',
  standalone: true,
  imports: [SELECT_IMPORTS],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _SingleSelectComponent<Option extends object = SelectOption, Value = unknown> extends _SelectComponent<
  Option,
  Value
> {
  readonly _multiple = false;
}

@UntilDestroy()
@Component({
  selector: 'y42-multi-select',
  standalone: true,
  imports: SELECT_IMPORTS,
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class _MultiSelectComponent<
  Option extends object = SelectOption,
  Value extends unknown[] = unknown[],
> extends _SelectComponent<Option, Value> {
  readonly _multiple = true;
}

export const SingleSelectComponent = [_SingleSelectComponent, SelectOptionDirective, SelectTriggerDirective];
export const MultiSelectComponent = [_MultiSelectComponent, SelectOptionDirective, SelectTriggerDirective];
