export const CURRENCY_LOCALE = {
  USD: 'en',
  EUR: 'en-euro',
  GBP: 'en-gb',
  BGJ: 'bg',
  JPY: 'chs',
  CZK: 'cs',
  DKK: 'da-dk',
  CHF: 'de-ch',
  ZAR: 'en-za',
  HUF: 'hu',
  PLN: 'pl',
  BRL: 'pt-br',
  RUB: 'ru',
  THB: 'th',
  VND: 'vi',
} as const;

export type Currency = keyof typeof CURRENCY_LOCALE;
export type Locale = (typeof CURRENCY_LOCALE)[Currency];

export interface FormatConfig {
  formatType: FormatType;
  decimalPlaces: number;
  negativeSymbol: NegativeSymbol;
  numeralFormat: string;
  displayType: DisplayType;
  separator: SeparatorType;
  locale: Currency | '';
  prefixOrSuffix: 'prefix' | 'suffix';
  customFormat: string;
}

export class StackFormatConfig {
  constructor(
    public format = 'inherit',
    public config: FormatConfig = {
      customFormat: '',
      decimalPlaces: 0,
      displayType: DisplayType.DisplayUnits,
      formatType: FormatType.NUMBER,
      locale: '',
      negativeSymbol: {
        show: true,
        type: 'symbol',
      },
      numeralFormat: '0a',
      prefixOrSuffix: 'prefix',
      separator: SeparatorType.NONE,
    },
  ) {}
}

export interface NegativeSymbol {
  show: boolean;
  type: 'symbol' | 'bracket';
}

export enum DisplayType {
  DisplayUnits = 'DISPLAY_UNITS',
  OrdinalNumber = 'ORDINAL_NUMBERS',
  None = 'NONE',
}

export enum SeparatorType {
  NONE = 'NONE',
  DOT = 'DOT',
  COMMA = 'COMMA',
}

export enum FormatType {
  NUMBER = 'NUMBER',
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
  CUSTOM = 'CUSTOM',
}
