<button
  mat-icon-button
  [type]="type"
  [disabled]="disabled"
  [color]="color"
  [ngClass]="classes"
  [class.custom-button-icon-compact]="size === 'compact'"
  [class.custom-button-icon-large]="size === 'large'"
>
  <y42-icon [icon]="icon" [faPrefix]="prefix" [ngClass]="iconClass" [size]="state.iconSize" />
</button>
