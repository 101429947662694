import { Injectable } from '@angular/core';
import { UserState, UserStoreModel } from '@examdojo/models/user';
import { EntityStore } from '@examdojo/state';

@Injectable({ providedIn: 'root' })
export class UserStore extends EntityStore<UserState, UserStoreModel, 'id'> {
  constructor() {
    super({
      name: 'users',
      idKey: 'id',
    });
  }
}
