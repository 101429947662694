import { ModuleWithProviders, NgModule } from '@angular/core';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { HotToastModule } from '@ngneat/hot-toast';
import { ToastComponent } from './toast.component';
import { DEFAULT_HOT_TOAST_STYLE } from './toast.model';

@NgModule({ imports: [ToastComponent] })
export class ToastModule {
  static forRoot(): ModuleWithProviders<HotToastModule> {
    return HotToastModule.forRoot({
      position: 'top-right',
      style: DEFAULT_HOT_TOAST_STYLE,
      icon: '',
      duration: 4000,
      className: 'hot-toast-success',
      loading: {
        icon: '',
        className: 'hot-toast-info',
      },
      warning: {
        icon: '',
        className: 'hot-toast-warning',
      },
      error: {
        icon: '',
        className: 'hot-toast-error',
      },
      info: {
        icon: '',
        className: 'hot-toast-info',
      },
      blank: {
        icon: '',
        className: 'hot-toast-info',
      },
    });
  }
}
