import { InjectionToken } from '@angular/core';

export enum EnvName {
  Local = 'local',
  Dev = 'dev',
  Staging = 'staging',
  Production = 'production',
}

export const ENV_NAME = new InjectionToken<EnvName>('Environment Name', {
  factory: () => EnvName.Local,
});
