import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Icon, IconComponent } from '@examdojo/core/icon';
import { provideFaIcons } from '@examdojo/icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faRectangleHistoryCirclePlus, faTimes } from '@fortawesome/pro-light-svg-icons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, skip, tap } from 'rxjs';
import { TextInputComponent } from '../input.component';

@UntilDestroy()
@Component({
  selector: 'dojo-search-input',
  standalone: true,
  imports: [
    NgIf,
    FontAwesomeModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    TextInputComponent,
    MatButtonModule,
    IconComponent,
  ],
  templateUrl: './search-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent implements OnInit {
  constructor() {
    provideFaIcons([faRectangleHistoryCirclePlus, faTimes]);
  }

  @Input() placeholder = 'Search';
  @Input() label = '';
  @Input() compact = false;
  @Input() hasInitialFocus = false;
  @Input() fullWidthField = false;
  @Input() icon: Icon = 'search';
  @Output() searchChanged = new EventEmitter<string>();

  @Input() formCtrl = new FormControl<string>('', { nonNullable: true });

  ngOnInit(): void {
    this.formCtrl.valueChanges
      .pipe(
        skip(1),
        debounceTime(300),
        tap((searchKey) => this.searchChanged.emit(searchKey)),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
