import { AfterContentInit, ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { BaseButton } from '@examdojo/core/button';
import { IS_PROD_BUILD } from '@examdojo/core/environment';
import { markFormAsSubmitted } from '@examdojo/core/form';
import { ReactiveLifecycleHooksDirective } from '@examdojo/core/reactive-lifecycle-hooks';
import { assertNonNullable } from '@examdojo/core/util/assert';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom, isObservable, Observable, switchMap } from 'rxjs';

export type FormConfirmFnReturnType<TReturn = unknown> =
  | Observable<TReturn>
  | Promise<TReturn>
  | TReturn
  | undefined
  | void;

export type FormConfirmFn<TFormGroup extends FormGroup = FormGroup, TReturn = unknown> = (
  form: TFormGroup,
) => FormConfirmFnReturnType<TReturn>;

@UntilDestroy()
@Directive({
  selector: '[submitButton]',
  standalone: true,
  exportAs: 'submitButton',
})
export class SubmitButtonDirective<TFormGroup extends FormGroup, TReturn = unknown>
  extends ReactiveLifecycleHooksDirective
  implements AfterContentInit
{
  constructor(
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly cdRef: ChangeDetectorRef,
    private readonly formGroupDirective: FormGroupDirective,
    private readonly buttonComponent: BaseButton,
  ) {
    super();
    this.buttonComponent.type.set('submit');
    this.formGroupDirective.ngSubmit
      .pipe(
        switchMap(() => this.submitForm()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  @Input({ required: true }) confirmFn!: FormConfirmFn<TFormGroup, TReturn>;

  @Output() confirm = new EventEmitter<TReturn | undefined | void>();

  private pending = false;

  ngAfterContentInit(): void {
    if (!IS_PROD_BUILD) {
      const form = this.formGroupDirective.form;
      assertNonNullable(form, 'form');
      assertNonNullable(this.confirmFn, 'this.confirmFn');

      setTimeout(() => {
        const element = this.elementRef.nativeElement;

        element.querySelectorAll('button').forEach((button) => {
          if (!button.type) {
            console.error(
              'Button without type attribute found. Please add type="button" to all buttons in the form dialog to prevent accidental submits.',
              button,
            );
          }
        });

        const submitButtons = element.querySelectorAll('button[type="submit"]');
        if (submitButtons.length > 1) {
          console.warn(
            `Multiple submit buttons found in form dialog. Please ensure that you only have one submit button.`,
            submitButtons,
          );
        }

        const forms = element.querySelectorAll('form');
        if (forms.length > 1) {
          console.warn(
            `Multiple forms found in form dialog. Please ensure that you don't wrap your form with a <form> tag yourself, as the form component already does that for you.`,
            forms,
          );
        }
      }, 1000);
    }
  }

  private async submitForm() {
    if (this.pending) {
      return;
    }

    this.setInProgress(true);
    const form = this.formGroupDirective.form as TFormGroup;
    await markFormAsSubmitted(form);

    if (!form.valid) {
      this.setInProgress(false);
      return;
    }
    try {
      const asyncFnOrResult = this.confirmFn(form);
      let result: FormConfirmFnReturnType<TReturn> = asyncFnOrResult;

      if (isObservable(asyncFnOrResult)) {
        result = await firstValueFrom(asyncFnOrResult.pipe(untilDestroyed(this)), { defaultValue: undefined });
      } else {
        result = await asyncFnOrResult;
      }

      this.confirm.emit(result);
    } catch (error) {
      console.error(error);
    } finally {
      this.setInProgress(false);
    }
  }

  private setInProgress(pending: boolean) {
    this.buttonComponent.pending = pending;
    this.pending = pending;
    this.cdRef.markForCheck();
  }
}
