import { Route } from '@angular/router';
import { AuthUrlParts, unauthenticatedGuard } from '@examdojo/auth';

export const authRoutes: Route[] = [
  {
    path: AuthUrlParts.Login,
    canActivate: [unauthenticatedGuard],
    loadComponent: () => import('@examdojo/auth').then((c) => c.SignInComponent),
  },
  {
    path: AuthUrlParts.Register,
    canActivate: [unauthenticatedGuard],
    loadComponent: () => import('@examdojo/auth').then((c) => c.SignUpComponent),
  },
  {
    path: AuthUrlParts.Otp,
    canActivate: [unauthenticatedGuard],
    loadComponent: () => import('@examdojo/auth').then((c) => c.SignInOTPComponent),
  },
];
