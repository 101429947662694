import { IS_PROD_BUILD } from '@examdojo/core/environment';
import { isNullish } from '@examdojo/util/nullish';
import { select } from '@ngneat/elf';
import {
  getActiveEntity,
  getActiveId,
  getAllEntities,
  getAllEntitiesApply,
  getEntitiesCount,
  getEntity,
  hasEntity,
  selectActiveEntity,
  selectActiveId,
  selectAllEntities,
  selectAllEntitiesApply,
  selectEntities,
  selectEntitiesCount,
  selectEntity,
  selectEntityByPredicate,
} from '@ngneat/elf-entities';
import {
  catchError,
  combineLatest,
  debounceTime,
  defer,
  distinctUntilChanged,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
} from 'rxjs';
import { EntityState, EntityStore, EntityType, getEntityType } from './entity-store';

export type UIModelFn<State extends EntityState, UIEntity extends getEntityType<State>> = (
  entity: getEntityType<State>,
) => UIEntity;

export type getPropsState<State extends EntityState> = State extends EntityState<infer _E, infer Props> ? Props : never;

export abstract class QueryEntity<
  State extends EntityState<EntityType>,
  Entity extends getEntityType<State> = getEntityType<State>,
  UIEntity extends Entity = Entity,
  IDKey extends string = string,
  IDType extends Entity[IDKey] = Entity[IDKey],
> {
  constructor(
    protected readonly store: EntityStore<State, Entity, IDKey, IDType>,
    /**
     * Additional Observable values, that all UI-entities depends on.
     */
    protected readonly getAllEntitiesTriggers$: () => Array<Observable<unknown>> = () => [],
    /**
     * Additional Observable values, that the active UI-entity depends on.
     */
    protected readonly getActiveEntityTriggers$: (id: IDType) => Array<Observable<unknown>> = () => [],

    /**
     * The mapping function to construct a UI-Entity from an Entity.
     */
    public readonly toUIModelFn: (entity: Entity) => UIEntity = (entity) => entity as UIEntity,
  ) {
    if (!IS_PROD_BUILD) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // ((window as any)['$$queries'] || {})[this.store.storeName] = this;
    }
  }

  readonly active$: Observable<UIEntity | null> = defer(() =>
    this.selectActiveId().pipe(
      switchMap((id) => {
        if (isNullish(id)) {
          return of(null);
        }

        return this.selectUIEntity(id);
      }),
    ),
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  readonly entities$: Observable<UIEntity[]> = defer(() =>
    combineLatest([
      ...this.getAllEntitiesTriggers$().map((trigger) => trigger.pipe(distinctUntilChanged())),
      this.selectAllEntities(),
    ]).pipe(
      debounceTime(0),
      map(() => this.getAllEntities()),
      map((entities) => entities.map((entity) => this.toUIModelFn(entity))),
    ),
  ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

  /**
   * Select the entire store's entity collection as a keyed object.
   */
  selectEntities() {
    return this.store.pipe(selectEntities()) as Observable<Record<string, Entity>>;
  }

  /**
   * Select the entire store's entity collection as a list.
   */
  selectAllEntities(options?: { filterEntity?(entity: Entity): boolean }): Observable<Entity[]> {
    if (!options) {
      return this.store.pipe(selectAllEntities());
    }

    return this.store.pipe(selectAllEntitiesApply(options));
  }

  /**
   * Select a single entity by ID.
   */
  selectEntity(id: IDType): Observable<Entity | null> {
    return this.store.pipe(
      selectEntity(id),
      map((entity) => entity ?? null),
    );
  }

  selectUIEntity(id: IDType): Observable<UIEntity | null> {
    const triggers$ = this.getActiveEntityTriggers$(id).map((trigger$) =>
      trigger$.pipe(
        catchError((err: unknown) => {
          console.error(err);
          return of(null);
        }),
        distinctUntilChanged(),
      ),
    );

    return combineLatest(triggers$.length ? triggers$ : [of(null)]).pipe(
      switchMap(() => this.selectEntity(id)),
      debounceTime(0),
      map((entity) => (entity ? this.toUIModelFn(entity) : null)),
    );
  }

  /**
   * Select the active entity's ID.
   */
  selectActiveId(): Observable<IDType | null> {
    return this.store.pipe(
      selectActiveId(),
      map((id) => id ?? null),
    );
  }

  /**
   * Select the active entity.
   */
  selectActive(): Observable<Entity | null> {
    return this.store.pipe(
      selectActiveEntity(),
      map((entity) => entity ?? null),
    );
  }

  /**
   * Select (find) a single entity by predicate.
   */
  selectEntityByPredicate(predicate: (entity: Entity) => boolean): Observable<Entity | null> {
    return this.store.pipe(
      selectEntityByPredicate(predicate),
      map((entity) => entity ?? null),
    );
  }

  /**
   * Select the store's entity collection size.
   */
  selectCount(): Observable<number> {
    return this.store.pipe(selectEntitiesCount());
  }

  /**
   * Select the store's loading state.
   */
  selectLoading(): Observable<boolean> {
    return this.store.pipe(select((state) => state.loading > 0));
  }

  /**
   * Select a property from the additional `props` sub-state.
   */
  selectProp<Prop extends keyof State['props']>(prop: Prop): Observable<State['props'][Prop]> {
    return this.store.pipe(select((state) => state.props[prop]));
  }

  select<R>(fn: (state: State) => R): Observable<R> {
    return this.store.pipe(select((state) => fn(state as State)));
  }

  getEntities() {
    return this.store.getValue().entities as Record<string, Entity>;
  }

  getAllEntities(options?: { filterEntity?(entity: Entity): boolean }): Entity[] {
    if (!options) {
      return this.store.query(getAllEntities());
    }

    return this.store.query(getAllEntitiesApply(options));
  }

  getAllUIEntities(options?: { filterEntity?(entity: Entity): boolean }): UIEntity[] {
    const entities = this.getAllEntities(options);

    if (!this.toUIModelFn) {
      return entities as UIEntity[];
    }

    return entities.map((entity) => this.toUIModelFn(entity));
  }

  getEntity(id: IDType): Entity | null {
    return this.store.query(getEntity(id as IDType)) ?? null;
  }

  getUIEntity(id: IDType): UIEntity | null {
    const entity = this.getEntity(id);

    if (!this.toUIModelFn || !entity) {
      return entity as UIEntity;
    }

    return this.toUIModelFn(entity);
  }

  getEntityByPredicate(predicate: (entity: Entity) => boolean): Entity | null {
    return this.getAllEntities().find(predicate) ?? null;
  }

  getEntitiesByPredicate(predicate: (entity: Entity) => boolean): Entity[] {
    return this.getAllEntities().filter(predicate);
  }

  getActiveId(): IDType | null {
    return this.store.query(getActiveId) ?? null;
  }

  getCount(): number {
    return this.store.query(getEntitiesCount());
  }

  getActive(): Entity | null {
    return this.store.query(getActiveEntity()) ?? null;
  }

  getActiveUIEntity(): UIEntity | null {
    const activeId = this.getActiveId() as IDType | null;
    return activeId ? this.getUIEntity(activeId) : null;
  }

  getProp<Prop extends keyof getPropsState<State>>(prop: Prop): getPropsState<State>[Prop] {
    return (this.store.getValue().props as object)[prop as keyof object];
  }

  getValue(): State {
    return this.store.getValue();
  }

  hasEntity(id: IDType): boolean {
    return this.store.query(hasEntity(id));
  }
}
