import { basePathIcons, SvgIcon } from '@examdojo/core/icon';

const iconsBasePath = `${basePathIcons}/external-logos`;

export const bigQueryIcon: SvgIcon = `${iconsBasePath}/big-query.svg`;
export const snowflakeIcon: SvgIcon = `${iconsBasePath}/snowflake.svg`;

export const bigQueryTableIcon: SvgIcon = `${iconsBasePath}/bq-table.svg`;
export const snowflakeTableIcon: SvgIcon = `${iconsBasePath}/snowflake-table.svg`;

export const googleCloudStorageIcon: SvgIcon = `${iconsBasePath}/google-cloud-storage.svg`;
export const azureBloblStorageIcon: SvgIcon = `${iconsBasePath}/azure-blob-storage.svg`;
export const amazonS3Icon: SvgIcon = `${iconsBasePath}/amazon-s3.svg`;

export const gitlabIcon: SvgIcon = `${iconsBasePath}/gitlab.svg`;
export const githubIcon: SvgIcon = `${iconsBasePath}/github.svg`;
