import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Angular's built-in Validators.email can't be used because it allows emails like 'foo@bar'.
 * These emails can't be used with Postmarkapp, the service that uses the email api to deliver emails to the users.
 *
 * This validator matches 'foo@bar.baz' and any string matching Angular's built-in email validator.
 */

export const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailValidator: ValidatorFn = (control: AbstractControl<string>): ValidationErrors | null => {
  return control.value.match(emailRegExp) ? null : { email: true };
};
