@if (faIcon) {
  <fa-icon
    [icon]="[faPrefix, faIcon]"
    [ngStyle]="extendedIconStyle"
    [class]="iconClass"
    [class.child-1em]="!keepFaIconRatio"
  />
} @else if (imgIcon) {
  <img [src]="imgIcon" [ngStyle]="extendedIconStyle" [alt]="alt" [class]="iconClass" />
} @else if (svgIcon) {
  <svg-icon [src]="svgIcon" [viewBox]="viewBox" [svgStyle]="extendedIconStyle" [class]="iconClass" />
}
