import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@examdojo/core/error-handling';
import { mapToVoid, rethrowError, shareOneReplay } from '@examdojo/core/rxjs';
import {
  USER_ROLE_ORDER,
  USER_UPDATE_ALLOWED_KEYS,
  UserHttpModel,
  UserRole,
  UserStoreModel,
  UserUpdateModel,
} from '@examdojo/models/user';
import { TableUpdateModel } from '@examdojo/supabase';
import { sanitizeObject } from '@examdojo/util/sanitize-object';
import { distinctUntilChanged, map, of, switchMap, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { UserHttpService } from './user-http.service';
import { UserStore } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserService {
  constructor(
    private readonly userHttpService: UserHttpService,
    private readonly userStore: UserStore,
    private readonly authService: AuthService,
    private readonly errorHandlerService: ErrorHandlerService,
  ) {}

  readonly currentUserRole$ = this.authService.currentUser$.pipe(
    switchMap((currentUser) => {
      if (!currentUser) {
        return of(undefined);
      }

      return this.userHttpService.fetchCurrentUserRole();
    }),
    this.errorHandlerService.catchError('[UserService]: Failed to fetch current user role', rethrowError(), {
      toast: 'Failed to fetch current user role',
    }),
    shareOneReplay(),
  );

  doesCurrentUserHaveRoleOrHigher(role: UserRole) {
    const roleIndex = USER_ROLE_ORDER.findIndex((r) => r === role);

    return this.currentUserRole$.pipe(
      map((currentUserRole) => {
        if (currentUserRole === null) {
          return false;
        }
        const currentUserRoleIndex = USER_ROLE_ORDER.findIndex((r) => r === currentUserRole);
        return currentUserRoleIndex >= roleIndex;
      }),
      distinctUntilChanged(),
    );
  }

  fetchActiveUserProfile() {
    return this.userHttpService.fetchActiveUserProfile().pipe(
      map((data) => this.mapUserHttpModelToStoreModel(data)),
      tap((user) => {
        this.userStore.upsertMany([user]);
        this.userStore.setActive(user.id);
      }),
      this.errorHandlerService.catchError('[UserService]: Failed to fetch current user profile', rethrowError(), {
        toast: 'Failed to fetch current user profile',
      }),
    );
  }

  fetchAll() {
    return this.userHttpService.fetchAll().pipe(
      tap((users) => this.userStore.upsertMany(users.map((user) => this.mapUserHttpModelToStoreModel(user)))),
      this.errorHandlerService.catchError('[UserService]: Failed to fetch users', rethrowError(), {
        toast: 'Failed to fetch users',
      }),
    );
  }

  update(id: UserStoreModel['id'], updateModel: UserUpdateModel) {
    const user = this.mapUpdateModelToHttpUpdateModel(updateModel);

    return this.userHttpService.update(id, user).pipe(
      tap((data) => {
        this.userStore.update(data.id, this.mapUserHttpModelToStoreModel(data));
      }),
      mapToVoid(),
      this.errorHandlerService.catchError('[UserService]: Updating user failed', rethrowError()),
    );
  }

  private mapUserHttpModelToStoreModel(questionHttpModel: UserHttpModel): UserStoreModel {
    return { ...questionHttpModel, origin: questionHttpModel.origin as unknown as UserStoreModel['origin'] };
  }

  private mapUpdateModelToHttpUpdateModel(updateModel: UserUpdateModel): TableUpdateModel<'users'> {
    return sanitizeObject(updateModel, USER_UPDATE_ALLOWED_KEYS);
  }
}
