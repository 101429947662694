<div
  class="custom-mat-form-wrapper"
  [ngClass]="{ 'compact-select': compact, 'micro-select': micro, 'borderless-select': borderless }"
>
  <div class="mat-form-label" y42FormFieldLabel *ngIf="label">
    <ng-container *ngIf="state.labelTemplate; else defaultLabelTmpl">
      <ng-container *ngTemplateOutlet="state.labelTemplate"></ng-container>
    </ng-container>

    <ng-template #defaultLabelTmpl>
      <span>{{ label }}</span>
      @if (tooltipHint) {
        <y42-info-icon [message]="tooltipHint" [infoIconDisabled]="formCtrl.disabled" />
      }
    </ng-template>
  </div>

  <mat-form-field
    class="!h-auto w-full"
    appearance="fill"
    [color]="color"
    [matTooltip]="state.tooltip"
    [class.full-width-field]="fullWidthField"
  >
    @if (nativeSelect) {
      <select
        matNativeControl
        [formControl]="formCtrl"
        [multiple]="_multiple"
        (change)="selectionChange.next(formCtrl.value)"
        data-test="select"
      >
        <option
          *ngFor="let option of state.options; trackBy: trackBy"
          [value]="option.value"
          [attr.data-test]="'option-value-' + option.value"
          [disabled]="option.disabled"
        >
          @if (optionTemplate ?? selectOptionDirective?.templateRef; as tmpl) {
            <ng-container
              *ngTemplateOutlet="
                tmpl;
                context: {
                  $implicit: option.option
                }
              "
            ></ng-container>
          } @else {
            {{ option.label }}
          }
        </option>
      </select>
    } @else {
      <mat-select
        [formControl]="formCtrl"
        [placeholder]="placeholder ?? ''"
        [multiple]="_multiple"
        [errorStateMatcher]="errorStateMatcher"
        [hideSingleSelectionIndicator]="hideSingleSelectionIndicator"
        [panelClass]="'custom-mat-select-panel ' + panelClass"
        (selectionChange)="selectionChange.next($event.value)"
        data-test="select"
      >
        @if (triggerTemplate ?? selectTriggerDirective?.templateRef; as tmpl) {
          <mat-select-trigger>
            <ng-container *ngTemplateOutlet="tmpl"></ng-container>
          </mat-select-trigger>
        }

        <mat-option
          *ngFor="let option of state.options; trackBy: trackBy"
          [value]="option.value"
          [attr.data-test]="'option-value-' + option.value"
          [disabled]="option.disabled"
        >
          @if (optionTemplate ?? selectOptionDirective?.templateRef; as tmpl) {
            <ng-container
              *ngTemplateOutlet="
                tmpl;
                context: {
                  $implicit: option.option
                }
              "
            ></ng-container>
          } @else {
            {{ option.label }}
          }
        </mat-option>
      </mat-select>
    }

    <ng-content></ng-content>

    <mat-error *ngIf="!hideErrors">
      <ng-container *ngIf="state.errorMessage">
        {{ state.errorMessage }}
      </ng-container>
    </mat-error>
  </mat-form-field>
</div>
