import { IconName } from '@fortawesome/fontawesome-svg-core';

const SvgIconEnding = 'svg' as const;
const ImgIconEndings = ['jpg', 'png', 'gif', 'webp', 'jpeg', 'ico'] as const;

export type FaIcon = IconName;
export type SvgIcon = `${string}.${typeof SvgIconEnding}`;
export type ImgIcon = `${string}.${(typeof ImgIconEndings)[number]}`;
export type Icon = FaIcon | ImgIcon | SvgIcon;

export const isSvgIcon = (icon: string): icon is SvgIcon => icon.endsWith(`.${SvgIconEnding}`);
export const isImgIcon = (icon: string): icon is ImgIcon =>
  ImgIconEndings.some((ending) => icon.endsWith(`.${ending}`));
export const isFaIcon = (icon: Icon): icon is FaIcon => !isSvgIcon(icon) && !isImgIcon(icon);
