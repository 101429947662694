import { Route } from '@angular/router';
import { authRoutes } from '@examdojo/auth';
import { NotFoundComponent } from '@examdojo/core/not-found';
import { RootUrlParts } from './app.model';
import { AuthGuard } from './auth/auth.guard';
import { HomeComponent } from './home/home.component';
import { getUserRoleGuard } from './user/user-role/user-role.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: HomeComponent,
  },

  ...authRoutes,
  {
    path: RootUrlParts.Cms,
    canActivate: [AuthGuard, getUserRoleGuard('CONTENT_CREATOR')],
    loadChildren: () => import('./cms/cms.routes').then((m) => m.CMS_ROUTES),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
