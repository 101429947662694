import { FormArray, FormControl, FormGroup } from '@angular/forms';

/**
 * Return a truthy value from the callback to break the loop.
 *
 * Example 1:
 *
 * The following call will return the first control that has been touched in the form.
 *
 * const form = new FormGroup(...);
 *
 * const firstTouchedControl = findFormControl(form, (control) => {
 *   return control.touched;
 * });
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findFormControl = <T extends Record<string, any>, U>(
  form: FormGroup<T> | FormArray<FormControl<U>> | FormControl<U>,
  fn: (control: FormControl<U>) => unknown,
): FormControl<U> | null => {
  const returnValue = recursiveFindFormControl(form, fn);
  return returnValue ? returnValue.formControl : null;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const recursiveFindFormControl = <T extends Record<string, any>, U>(
  form: FormGroup<T> | FormArray<FormControl<U>> | FormControl<U>,
  fn: (control: FormControl<U>) => unknown,
): {
  returnValue: unknown;
  formControl: FormControl<U> | null;
} | null => {
  if (form instanceof FormControl) {
    return {
      returnValue: fn(form),
      formControl: form,
    };
  } else {
    for (const value of Object.values(form.controls)) {
      const returnValue = recursiveFindFormControl(value, fn);
      if (returnValue?.returnValue) {
        return returnValue;
      }
    }
    return null;
  }
};
