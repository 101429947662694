<y42-text-input
  [formCtrl]="formCtrl"
  [label]="label"
  [type]="state.hiddenPassword ? 'password' : 'text'"
  [required]="required"
  [errorMessages]="errorMessages"
  [color]="color"
  [tooltipHint]="tooltipHint"
  [hideErrors]="hideErrors"
  [autocomplete]="autocomplete"
  [placeholder]="placeholder"
>
  <ng-container y42InputSuffix>
    <button
      class="custom-button-icon-compact"
      type="button"
      tabindex="-1"
      mat-icon-button
      (click)="togglePasswordVisibility()"
    >
      <y42-icon [icon]="state.hiddenPassword ? 'eye' : 'eye-slash'" [size]="16" />
    </button>
  </ng-container>
</y42-text-input>
