<div class="custom-mat-form-wrapper" [class.compact-field]="compact" [class.custom-input-number]="type === 'number'">
  <div class="mat-form-label" y42FormFieldLabel *ngIf="label">
    <ng-container *ngIf="state.labelTemplate; else defaultLabelTmpl">
      <ng-container *ngTemplateOutlet="state.labelTemplate"></ng-container>
    </ng-container>

    <ng-template #defaultLabelTmpl>
      <span>{{ label }}</span>

      @if (tooltipHint) {
        <y42-info-icon [message]="tooltipHint" [infoIconDisabled]="formCtrl.disabled" />
      }
    </ng-template>
  </div>

  <mat-form-field
    class="w-full"
    appearance="fill"
    [color]="color"
    [matTooltip]="state.tooltip"
    [class.full-width-field]="fullWidthField"
  >
    <ng-container matPrefix>
      <ng-content select="[y42InputPrefix]"></ng-content>
    </ng-container>

    <!-- https://github.com/angular/angular/issues/13243 -->
    <ng-container *ngIf="type === 'number'">
      <input
        matInput
        type="number"
        [formControl]="formCtrl"
        [placeholder]="placeholder ?? ''"
        [attr.autocomplete]="autocomplete"
        [readonly]="readOnly"
        [errorStateMatcher]="errorStateMatcher"
        [required]="required"
        [tabIndex]="tabIndex"
        (input)="input.emit($event)"
        (keyup)="keyup.emit($event)"
        (blur)="blur.emit($event)"
        [attr.cdkFocusInitial]="hasInitialFocus"
      />
    </ng-container>

    <ng-container *ngIf="type === 'textarea'">
      <textarea
        matInput
        [type]="type"
        [formControl]="formCtrl"
        [placeholder]="placeholder ?? ''"
        [ngClass]="{ '!uppercase': upperCase, 'hide-resize': !resizable }"
        [attr.autocomplete]="autocomplete"
        [readonly]="readOnly"
        [errorStateMatcher]="errorStateMatcher"
        [required]="required"
        [tabIndex]="tabIndex"
        (input)="input.emit($event)"
        (keyup)="keyup.emit($event)"
        (blur)="blur.emit($event)"
        [attr.cdkFocusInitial]="hasInitialFocus"
      >
      </textarea>
    </ng-container>

    <ng-container *ngIf="type !== 'number' && type !== 'textarea'">
      <input
        matInput
        [type]="type"
        [formControl]="formCtrl"
        [placeholder]="placeholder ?? ''"
        [attr.autocomplete]="autocomplete"
        [readonly]="readOnly"
        [errorStateMatcher]="errorStateMatcher"
        [required]="required"
        [tabIndex]="tabIndex"
        [ngClass]="{ '!uppercase': upperCase }"
        [attr.cdkFocusInitial]="hasInitialFocus"
        (input)="input.emit($event)"
        (keyup)="keyup.emit($event)"
        (blur)="blur.emit($event)"
      />
    </ng-container>

    <ng-content></ng-content>
    <ng-container matSuffix>
      <ng-content select="[y42InputSuffix]"></ng-content>
      <ng-container *ngIf="enableCopyToClipboard">
        <y42-copy-to-clipboard *ngIf="formCtrl.value" [value]="formCtrl.value" />
      </ng-container>
    </ng-container>
    <mat-error *ngIf="!hideErrors && state.errorMessage">
      {{ state.errorMessage }}
    </mat-error>
  </mat-form-field>
</div>
