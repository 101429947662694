import { InjectionToken, Injector } from '@angular/core';

export type FeatureFlag = string;

export type FeatureFlagValue = string | number | boolean | object;
export type FeatureFlagTokenValue = FeatureFlagValue | ((injector: Injector) => FeatureFlagValue);

export type ResolveFeatureFlagValue<T> = {
  [K in keyof T]: T[K] extends (injector: Injector) => FeatureFlagValue ? ReturnType<T[K]> : T[K];
};

export type FeatureFlagValues = ResolveFeatureFlagValue<FeatureFlags>;

export type FeatureFlags<T extends FeatureFlag = FeatureFlag> = Record<T, FeatureFlagTokenValue>;

export const FEATURE_FLAGS = new InjectionToken<FeatureFlags>('Feature Flags', {
  factory: () => ({}),
});
