import { Observable } from 'rxjs';

export abstract class UserEnvironmentService {
  abstract get timezone(): string | null;
  abstract get locale(): string | null;
  abstract get colorScheme(): string | null;

  abstract readonly timezone$: Observable<string | null>;
  abstract readonly locale$: Observable<string | null>;
  abstract readonly colorScheme$: Observable<string | null>;
}
