<div class="message-wrapper">
  @if (icon) {
    <y42-icon [icon]="icon" class="message-icon" [size]="42" />
  }

  @if (header) {
    <p class="message-title">{{ header }}</p>
  }

  @if (description) {
    <p class="message-description">{{ description }}</p>
  } @else {
    <div class="message-description" [class.hidden]="!ngContentDescription.children.length" #ngContentDescription>
      <ng-content select="[y42FullscreenMessageDescription]" />
    </div>
  }

  <div class="mt-6">
    <div #ngContentFooter>
      <ng-content select="[y42FullscreenMessageFooter]" />
    </div>
    @if (!ngContentFooter.children.length) {
      <y42-button class="w-full" [color]="'primary'" (click)="goToHome()"> Go to Home </y42-button>
    }
  </div>
</div>
