import { IS_PROD_BUILD } from '@examdojo/core/environment';
import { ensureArray } from '@examdojo/util/ensure-array';
import { isNotNullish } from '@examdojo/util/nullish';
import { createStore, deepFreeze, elfHooks, OrArray, setProp, setProps, withProps } from '@ngneat/elf';
import {
  addEntities,
  deleteEntities,
  hasEntity,
  setActiveId,
  setEntities,
  updateEntities,
  upsertEntities,
  withActiveId,
  withEntities,
  updateEntitiesByPredicate,
  updateEntitiesIds,
} from '@ngneat/elf-entities';
import { UpdateFn } from '@ngneat/elf-entities/src/lib/update.mutation';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type EntityType = Record<string, any>;

export interface EntityState<
  Entity extends EntityType = EntityType,
  Props extends object = object,
  IDType extends string = string,
> {
  entities: Record<IDType, Entity>;
  ids: IDType[];
  loading: number;
  props: Props;
  activeId: string | null;
}

export type getEntityType<State extends EntityState> = State extends EntityState<infer E> ? E : never;
export type getIDType<State extends EntityState> = State extends EntityState<infer _E, infer _P, infer IDType>
  ? IDType
  : never;

export interface EntityStoreConfig<State extends EntityState> {
  name: string;
  idKey: keyof getEntityType<State>;
  initialPropsState?: State['props'] & {
    loading?: number;
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type PartialEntityWithID<Entity extends EntityType, IDKey extends string> = Partial<Entity> & {
  [ID in IDKey]: Entity[ID];
};

if (!IS_PROD_BUILD) {
  elfHooks.registerPreStoreUpdate((_currentState, nextState, _storeName) => {
    return deepFreeze(nextState);
  });
}

export abstract class EntityStore<
  State extends EntityState<EntityType>,
  Entity extends getEntityType<State> = getEntityType<State>,
  IDKey extends string = string,
  IDType extends Entity[IDKey] = Entity[IDKey],
> {
  constructor(private readonly entityStoreConfig: EntityStoreConfig<State>) {
    if (!IS_PROD_BUILD) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // ((window as any)['$$stores'] || {})[this.entityStoreConfig.name] = this;
    }
  }

  protected readonly store = createStore(
    { name: this.entityStoreConfig.name },
    withEntities<Entity, IDKey>({ idKey: this.entityStoreConfig.idKey as IDKey }),
    withActiveId(),
    withProps<Pick<State, 'props' | 'loading'>>({
      props:
        (() => {
          const { loading: _, ...props } = this.entityStoreConfig.initialPropsState ?? {};
          return props;
        })() || {},
      loading: this.entityStoreConfig.initialPropsState?.loading ?? 0,
    }),
  );

  readonly storeName = this.entityStoreConfig.name;
  readonly idKey = this.entityStoreConfig.idKey as IDKey;
  readonly pipe = this.store.pipe.bind(this.store);
  readonly query = this.store.query.bind(this.store);
  readonly reset = this.store.reset.bind(this.store);
  readonly updateStore = this.store.update.bind(this.store);

  getValue(): State {
    return this.store.getValue() as State;
  }

  hasEntity(id: IDType): boolean {
    return this.store.query(hasEntity(id));
  }

  create(entity: OrArray<Entity>): void {
    if (!IS_PROD_BUILD) {
      ensureArray(entity).forEach((e) => {
        if (e[this.idKey] && this.store.getValue().entities[e[this.idKey]]) {
          console.warn(`[EntityStore.create]: entity with id "${e[this.idKey]}" exists already`);
        }
      });
    }

    this.store.update(addEntities(entity));
  }

  update(id: OrArray<IDType>, updater: Partial<Entity>): void;
  update(id: OrArray<IDType>, updater: (entity: Entity) => Entity): void;
  update(id: OrArray<IDType>, updater: UpdateFn<Entity>): void {
    if (!IS_PROD_BUILD) {
      ensureArray(id).forEach((entityId) => {
        if (!this.store.getValue().entities[entityId]) {
          console.warn(`[EntityStore.update]: entity with id "${entityId}" not found`);
        }
      });
    }

    this.store.update(updateEntities(id, updater));
  }

  updateEntitiesByPredicate(predicate: (item: Entity) => boolean, updater: UpdateFn<Entity>) {
    this.store.update(updateEntitiesByPredicate(predicate, updater));
  }

  updateEntitiesIds(oldId: OrArray<IDType>, newId: OrArray<IDType>) {
    this.store.update(updateEntitiesIds(oldId, newId));
  }

  renameID(id: IDType, newId: IDType, entity?: Partial<Entity>): void {
    if (!IS_PROD_BUILD && !this.store.getValue().entities[id]) {
      console.warn(`[EntityStore.updateID]: entity with id "${id}" not found`);
    }

    const newEntity = {
      ...this.getValue().entities[id],
      ...(entity || {}),
      [this.idKey]: newId,
    } as Entity;

    const activeId = this.store.getValue().activeId;

    if (isNotNullish(activeId)) {
      this.store.update(deleteEntities(id), addEntities(newEntity), setActiveId(newId));
    } else {
      this.store.update(deleteEntities(id), addEntities(newEntity));
    }
  }

  set(entities: Entity[]): void {
    this.store.update(
      setEntities(entities),
      setProp('loading', (loading) => (loading > 0 ? loading - 1 : 0)),
    );
  }

  upsert(id: Entity[IDKey], entity: Entity): void {
    this.store.update(upsertEntities({ [this.idKey]: id, ...entity }));
  }

  upsertMany(entities: Entity[]): void {
    this.store.update(upsertEntities(entities));
  }

  delete(ids: OrArray<Entity[IDKey]>): void {
    if (!IS_PROD_BUILD) {
      (Array.isArray(ids) ? ids : [ids]).forEach((id) => {
        if (!this.store.getValue().entities[id]) {
          console.warn(`[EntityStore.delete]: entity with id "${id}" not found`);
        }
      });
    }

    this.store.update(deleteEntities(ids));
  }

  setActive(id: getIDType<State> | null): void {
    if (!IS_PROD_BUILD && !!id && !this.store.getValue().entities[id as Entity[IDKey]]) {
      console.warn(`[EntityStore.setActive]: entity with id "${id}" not found`);
    }

    this.store.update(setActiveId(id) ?? null);
  }

  setLoading(loading: boolean): void {
    this.store.update(
      setProp('loading', (current) => {
        const newLoading = loading ? current + 1 : current - 1;
        return newLoading < 0 ? 0 : newLoading;
      }),
    );
  }

  updateProps(props: (props: State['props']) => Partial<State['props']>): void {
    this.store.update(
      setProps((state) => {
        const st = {
          props: {
            ...state.props,
            ...props(state.props),
          },
        };

        return st;
      }),
    );
  }
}
