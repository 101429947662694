import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalizedString } from '../localized-string.model';
import { LocalizeService } from './localize.service';

@Pipe({
  name: 'localize',
  standalone: true,
})
export class LocalizePipe implements PipeTransform {
  constructor(private readonly localizeService: LocalizeService) {}

  transform(localizedString: LocalizedString): Observable<string> {
    return this.localizeService.localize(localizedString);
  }
}
