import { Injectable } from '@angular/core';
import { DEFAULT_LANGUAGE, InternationalizationService, SupportedLanguage } from '@examdojo/core/i18n';

const LANGUAGE_SETTING_STORAGE_KEY = 'user_language';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  constructor(private readonly internationalizationService: InternationalizationService) {}

  readonly activeLanguage$ = this.internationalizationService.activeLanguage$;

  setActiveLang(lang: SupportedLanguage) {
    this.internationalizationService.setActiveLang(lang);
    localStorage.setItem(LANGUAGE_SETTING_STORAGE_KEY, lang);
  }

  loadLanguageFromLocalStorage() {
    const lang = (localStorage.getItem(LANGUAGE_SETTING_STORAGE_KEY) as SupportedLanguage | null) ?? DEFAULT_LANGUAGE;
    this.internationalizationService.setActiveLang(lang);
  }
}
