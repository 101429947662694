<div class="mx-auto flex h-full w-full max-w-[380px] flex-col justify-center px-4 py-4">
  <div class="text-typography-primary dark:font-medium-dark pb-6 text-center text-xl font-medium">
    {{ 'examdojo.create_account' | transloco }}
  </div>

  <form [formGroup]="form" class="flex w-full flex-col" autocomplete="off">
    <div class="mb-4 grid grid-cols-2 gap-4">
      <y42-text-input
        label="First name"
        [formCtrl]="form.controls.firstName"
        [errorMessages]="{
          required: 'First name is required'
        }"
      />

      <y42-text-input
        label="Last name"
        [formCtrl]="form.controls.lastName"
        [errorMessages]="{
          required: 'Last name is required'
        }"
      />
    </div>

    <y42-text-input
      class="mb-4"
      [label]="'Email'"
      [type]="'email'"
      [formCtrl]="form.controls.email"
      [errorMessages]="{
        required: 'Email is required',
        exists: 'Email already exists',
        api: 'Could not create account',
        email: 'Enter a valid email'
      }"
    />
    <y42-user-password-input [formCtrl]="form.controls.password" class="mb-6" [label]="'Password'" />

    @if (error) {
      <mat-error class="mb-4">
        {{ error }}
      </mat-error>
    }

    <y42-button
      color="primary"
      class="w-full"
      type="submit"
      submitButton
      [confirmFn]="confirmFn"
      [attr.data-test]="'register-button-create-account'"
    >
      Create account
    </y42-button>
  </form>

  <p class="action-text mt-3 text-center">
    {{ 'examdojo.already_have_an_account' | transloco }}
    <a class="dashed-link" [routerLink]="loginUrl">{{ 'examdojo.sign_in' | transloco }}</a>
  </p>
</div>
