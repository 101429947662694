import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { connectState } from '@examdojo/angular/util';
import {
  AnimationDuration,
  getAnimationAppearDisappear,
  getAnimationAppearDisappearWithHeight,
} from '@examdojo/animation';
import { LogoLoaderModule } from '@examdojo/core/logo-loader';
import { distinctUntilChanged, map, of } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { AppLoadingService } from '../app-loading.service';

@UntilDestroy()
@Component({
  selector: 'y42-loading-indicator',
  standalone: true,
  imports: [NgIf, NgClass, LogoLoaderModule],
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    getAnimationAppearDisappear({ enter: AnimationDuration.Short }),
    getAnimationAppearDisappearWithHeight(),
  ],
})
export class LoadingIndicatorComponent {
  constructor(private readonly appLoadingService: AppLoadingService) {}

  @Input() transparentBackground = false;

  readonly state = connectState({
    isLoading: this.appLoadingService.loadingStatus$.pipe(
      map((status) => status.loading),
      distinctUntilChanged(),
      switchMap((loading) => (loading ? of(true) : of(false).pipe(debounceTime(500)))),
    ),
    statusDescription: this.appLoadingService.loadingStatus$.pipe(map((status) => status.description || '')),
  });
}
