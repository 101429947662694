import { Injectable } from '@angular/core';
import posthog from 'posthog-js';

@Injectable({
  providedIn: 'root',
})
export class PosthogErrorHandlingService {
  identifyUser(userId: string, traits?: Record<string, unknown>): void {
    posthog.identify(userId, traits);
  }

  setUserTraits(traits: Record<string, unknown>): void {
    posthog.capture('$set', {
      $set: traits,
    });
  }

  resetUser(): void {
    posthog.reset();
  }

  trackEvent(event: string | Error, context?: Record<string, unknown>) {
    return this.track(event, context);
  }

  track(event: unknown, context?: Record<string, unknown>) {
    let error: Error;

    switch (typeof event) {
      case 'string':
        error = new Error(event);
        break;
      case 'object':
        if (event instanceof Error) {
          error = event;
          break;
        }
        console.warn('Cannot track non-Error object event with PostHog', event);
        return;
      default:
        console.warn('Cannot track unsupported event type with PostHog', event);
        return;
    }

    return posthog.captureException(error, context);
  }
}
