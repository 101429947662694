import { EntityState } from '@examdojo/state';
import { TableInsertModel, TableRowModel, TableUpdateModel } from '@examdojo/supabase';
import { UserOriginDetail, UserOriginType } from './user-origin.model';

export type UserHttpModel = Pick<
  TableRowModel<'users'>,
  | 'course_id'
  | 'course_level'
  | 'email'
  | 'final_exam_date'
  | 'first_name'
  | 'id'
  | 'last_name'
  | 'onboarded_app'
  | 'onboarded_topic_practice'
  | 'origin'
  | 'profile_pic'
  | 'programme'
  | 'school_name'
  | 'updated_at'
>;

export interface UserOrigin {
  type: UserOriginType | string;
  detail: UserOriginDetail;
}

export type UserStoreModel = Omit<UserHttpModel, 'origin'> & {
  origin: UserOrigin | null;
};

export type UserUIModel = UserStoreModel & {
  fullName: string;
};

export interface UserState extends EntityState<UserStoreModel> {}

export const USER_CREATE_ALLOWED_KEYS = [
  'email',
  'first_name',
  'last_name',
  'profile_pic',
  'course_id',
  'final_exam_date',
  'programme',
  'school_name',
  'onboarded_topic_practice',
  'onboarded_app',
  'origin',
] satisfies Array<keyof TableInsertModel<'users'>>;

export type UserCreateModel = Pick<TableInsertModel<'users'>, (typeof USER_CREATE_ALLOWED_KEYS)[number]>;

export const USER_UPDATE_ALLOWED_KEYS = [...USER_CREATE_ALLOWED_KEYS] satisfies Array<keyof TableUpdateModel<'users'>>;

export type UserUpdateModel = Pick<TableUpdateModel<'users'>, (typeof USER_UPDATE_ALLOWED_KEYS)[number]>;
