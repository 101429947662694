import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ENV_NAME, EnvName, IS_PROD_BUILD } from '@examdojo/core/environment';
import { assertNonNullable } from '@examdojo/core/util/assert';
import { getErrorMessage } from '@examdojo/core/util/get-error-message';
import { RichToastMessage, ToastService } from '@examdojo/toast';
import { catchError, Observable, ObservableInput, ObservedValueOf, OperatorFunction, Subject } from 'rxjs';
import { PosthogErrorHandlingService } from './posthog/posthog-error-handling.service';

type ErrorToast = string | RichToastMessage;
export type ErrorContext = Record<string, unknown>;
export type ErrorToastOptions<E = unknown> = ErrorToast | ((err: E | undefined) => ErrorToast | undefined);

export interface ErrorHandlerOptions<E = unknown> {
  toast?: ErrorToastOptions<E>;
  context?: object;
  tags?: Record<string, string>;
  err?: unknown;
}

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService extends ErrorHandler {
  constructor(
    private readonly posthogErrorHandlingService: PosthogErrorHandlingService,
    private readonly injector: Injector,
  ) {
    super();
  }

  private readonly error$$ = new Subject<{
    message: string;
    err: unknown;
  }>();
  readonly error$ = this.error$$.asObservable();

  /**
   * This method is called by Angular - **do not use it**.
   *
   * Please use `ErrorHandlerService.error` instead.
   * @param error
   * @param options
   * @deprecated Please use `ErrorHandlerService.error` instead
   * @private
   */
  override handleError(error: unknown, options?: ErrorHandlerOptions): void {
    super.handleError(error);
    this.posthogErrorHandlingService.track(error);

    const envName = this.injector.get(ENV_NAME);
    this.handleErrorHandlerOptions(error, {
      ...(options || {}),
      toast: [EnvName.Local].includes(envName)
        ? (err) =>
            ({
              title: 'Unhandled Error',
              description: getErrorMessage(err),
            }) satisfies ErrorToastOptions
        : undefined,
    });
  }

  /**
   * Use this in place of the regular `rxjs/catchError`
   *
   * @param errorMessage the technical error message that will be displayed in PostHog
   * @param selector function that returns a new Observable value
   * @params options provide additional context or a toast message to be displayed
   */
  catchError<I, O extends ObservableInput<unknown>>(
    errorMessage: string,
    selector: (err: unknown, caught: Observable<I>) => O,
    options?: Omit<ErrorHandlerOptions, 'err'>,
  ): OperatorFunction<I, I | ObservedValueOf<O>> {
    return catchError((err: unknown, caught) => {
      this.error(errorMessage, {
        toast: options?.toast,
        context: options?.context,
        err,
        tags: { ...(options?.tags ?? {}), ...this.getTagsForError(err) },
      });
      return selector(err, caught);
    });
  }

  /**
   * Reports an event to PostHog and marks it as an error
   * @param errorMessage
   * @param options
   */
  error(errorMessage: string, options?: ErrorHandlerOptions): void {
    if (IS_PROD_BUILD) {
      console.error(errorMessage, options?.context || '');
    } else {
      // This will produce a stacktrace in the console, which makes debugging a lot easier
      console.error(new Error(errorMessage));

      if (options?.err) {
        console.error('[Original error]', options.err);
      }
      if (options?.context) {
        console.error('[Error Context]', options.context);
      }
    }

    this.captureMessage({
      errorMessage,
      level: 'error',
      options,
    });
    this.error$$.next({
      message: errorMessage,
      err: options?.err,
    });
  }

  /**
   * Reports an event to PostHog and marks it as a log
   * @param errorMessage
   * @param options
   */
  log(errorMessage: string, options?: ErrorHandlerOptions): void {
    if (!IS_PROD_BUILD) {
      console.warn(errorMessage, options?.context || '');
    }
    this.captureMessage({
      errorMessage,
      level: 'log',
      options,
    });
  }

  assertNonNullable(value: unknown, name: string, options?: ErrorHandlerOptions): asserts value {
    try {
      assertNonNullable(value, name);
    } catch (err) {
      this.error((err as Error).message, options);
      throw err;
    }
  }

  getBackendErrorMessage(err: unknown | Error | HttpErrorResponse): string {
    const error: string | unknown[] | { [key: string]: unknown } | undefined =
      err instanceof HttpErrorResponse ? err.error : err;

    if (err instanceof Error) {
      return err.message;
    }

    if (typeof error === 'string') {
      return error;
    }

    if (error && typeof error === 'object' && !Array.isArray(error)) {
      for (const key of Object.keys(error)) {
        if (error?.[key]) {
          return this.getBackendErrorMessage(error[key]);
        }
      }
    }

    if (Array.isArray(error)) {
      return this.getBackendErrorMessage(error[0]);
    }

    return '';
  }

  private captureMessage({
    errorMessage,
    options,
  }: {
    errorMessage: string;
    level: 'error' | 'warning' | 'log';
    options?: ErrorHandlerOptions;
  }): void {
    const { context, err } = options || {};
    const contextWithError = {
      ...context,
      ...(err ? { originalError: err } : {}),
    };

    this.posthogErrorHandlingService.trackEvent(errorMessage, contextWithError);

    this.handleErrorHandlerOptions(options?.err, options);
  }

  private handleErrorHandlerOptions(err: unknown | undefined, options?: ErrorHandlerOptions): void {
    const toast = options?.toast;
    const message = toast ? (typeof toast === 'function' ? toast(err) : toast) : undefined;

    if (message) {
      // We want to silence the HTTP errors for now, while they are not handled by the backend as user-facing errors
      //
      // if (err instanceof HttpErrorResponse) {
      //   const backendErrorMessage = this.getBackendErrorMessage(err);
      //   const errorMessage =
      //     typeof message === 'object' ? message?.markdown ?? backendErrorMessage : backendErrorMessage;
      //
      //   message = {
      //     title: typeof message === 'string' ? message : message.title,
      //     markdown: errorMessage.trim() ? ` \`\`\` ${errorMessage.trim()} \`\`\` ` : undefined,
      //   };
      // }

      this.injector.get(ToastService).error(message);
    }
  }

  private getTagsForError(err: unknown): Record<string, string> {
    const tags: Record<string, string> = {};

    if (err instanceof HttpErrorResponse) {
      tags['http_status'] = `${err.status}`;
    }

    return tags;
  }
}
