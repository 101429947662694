import { ChangeDetectionStrategy, Component, Input, model } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { IonContent, IonHeader, IonTitle, IonToolbar, ModalController } from '@ionic/angular/standalone';
import { TranslocoPipe } from '@jsverse/transloco';
import { connectState } from '@examdojo/angular/util';
import { ButtonComponent } from '@examdojo/core/button';
import { LANGUAGE_TO_LABEL, SUPPORTED_LANGUAGES, SupportedLanguage } from '@examdojo/core/i18n';
import { assertNonNullable } from '@examdojo/util/assert';
import { map } from 'rxjs';

@Component({
  selector: 'exam-language-picker-dialog',
  standalone: true,
  imports: [ButtonComponent, IonTitle, TranslocoPipe, IonToolbar, IonHeader, IonContent],
  templateUrl: './language-picker-dialog.component.html',
  styleUrl: './language-picker-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePickerDialogComponent {
  constructor(private readonly modalCtrl: ModalController) {}

  // Unfortunately Ionic won't support ModelSignal in the ModalController.componentProps
  // So we need this extra input
  @Input({ required: true }) set initialSelection(lang: SupportedLanguage) {
    this.selectedLanguage.set(lang);
  }

  readonly selectedLanguage = model<SupportedLanguage | undefined>(undefined);

  readonly languages$ = toObservable(this.selectedLanguage).pipe(
    map((activeLangCode) =>
      SUPPORTED_LANGUAGES.map((code) => ({
        code,
        label: LANGUAGE_TO_LABEL[code],
        isSelected: code === activeLangCode,
      })),
    ),
  );

  readonly state = connectState({
    languages: this.languages$,
  });

  selectLanguage(lang: SupportedLanguage) {
    this.selectedLanguage.set(lang);
  }

  save() {
    const selectedLanguage = this.selectedLanguage();
    assertNonNullable(selectedLanguage, 'selectedLanguage');
    this.modalCtrl.dismiss(selectedLanguage);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
