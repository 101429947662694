<button
  [type]="type()"
  [disabled]="pending || disabled()"
  mat-flat-button
  [id]="id"
  [color]="color"
  class="whitespace-nowrap"
  [ngClass]="class"
  [class.compact-button]="compact"
  [class.micro-button]="micro"
  [class.stretched-label]="isLabelStretched"
  [class.full-width-button]="fullWidth"
>
  <ng-container *ngIf="label">
    {{ label }}
  </ng-container>
  <ng-container *ngIf="!label">
    <ng-content></ng-content>
  </ng-container>
  <div *ngIf="pending" class="pending-layer">
    <mat-spinner [diameter]="16" [ngClass]="color ? 'quaternary-spinner' : 'secondary-spinner'"></mat-spinner>
  </div>
</button>
