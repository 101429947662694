import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { findFormControl } from './find-form-control';

type MarkAs = 'dirty' | 'touched' | 'pristine' | 'untouched';

export interface MarkAllAsOptions {
  updateValueAndValidity?: boolean;
}

/**
 * Marks all form-controls (that are not yet in the specified status) with the specified status.
 * Also works for nested form-groups and arrays (and their form-controls respectively).
 */
export function markAllAs(
  markAs: MarkAs,
  form: FormGroup | FormArray,
  { updateValueAndValidity = true }: MarkAllAsOptions = {},
): void {
  findFormControl(form, (ctrl) => {
    if (isControlInState(ctrl, markAs)) {
      return;
    }

    markControlAs(ctrl, markAs);
    if (updateValueAndValidity) {
      ctrl.updateValueAndValidity();
    }
  });

  if (updateValueAndValidity) {
    form.updateValueAndValidity();
  }
}

function isControlInState(ctrl: FormControl, markAs: MarkAs): boolean {
  switch (markAs) {
    case 'dirty':
      return ctrl.dirty;
    case 'pristine':
      return ctrl.pristine;
    case 'touched':
      return ctrl.touched;
    case 'untouched':
      return ctrl.untouched;
  }
}

function markControlAs(ctrl: FormControl, markAs: MarkAs): void {
  switch (markAs) {
    case 'dirty':
      return ctrl.markAsDirty();
    case 'pristine':
      return ctrl.markAsPristine();
    case 'touched':
      return ctrl.markAsTouched();
    case 'untouched':
      return ctrl.markAsUntouched();
  }
}
