import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { UntilDestroy } from '@ngneat/until-destroy';
import { connectState } from '@examdojo/angular/util';
import { IconModule } from '@examdojo/core/icon';
import { TextInputComponent, _InputComponent } from '@examdojo/core/input';
import { provideFaIcons } from '@examdojo/icons';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'y42-password-input',
  standalone: true,
  imports: [ReactiveFormsModule, MatButtonModule, IconModule, TextInputComponent],
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordInputComponent {
  constructor() {
    provideFaIcons([faEye, faEyeSlash]);
  }

  @Input({ required: true }) formCtrl!: FormControl<string | null | undefined>;
  @Input() label: _InputComponent['label'] = 'Password';
  @Input() required = false;
  @Input() errorMessages?: _InputComponent['errorMessages'];
  @Input() color?: _InputComponent['color'];
  @Input() tooltipHint?: _InputComponent['tooltipHint'];
  @Input() autocomplete?: HTMLInputElement['autocomplete'];
  @Input() placeholder?: HTMLInputElement['placeholder'];
  @Input() hideErrors = false;

  private readonly hiddenPassword$$ = new BehaviorSubject(true);

  readonly state = connectState({
    hiddenPassword: this.hiddenPassword$$,
  });

  togglePasswordVisibility(): void {
    this.hiddenPassword$$.next(!this.state.hiddenPassword);
  }
}
