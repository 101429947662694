import { Injectable, OnDestroy } from '@angular/core';
import { IS_PROD_BUILD } from '@examdojo/core/environment';
import { Store as ElfStore, StoreDef } from '@ngneat/elf';

@Injectable()
export abstract class Store<State extends object = object> implements OnDestroy {
  constructor(protected readonly store: ElfStore<StoreDef<State>>) {
    if (!IS_PROD_BUILD) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ((window as any)['$$stores'] || {})[this.store.name] = this;
    }
  }

  readonly storeName = this.store.name;
  readonly pipe = this.store.pipe.bind(this.store);
  readonly reset = this.store.reset.bind(this.store);
  readonly query = this.store.query.bind(this.store);
  readonly updateStore = this.store.update.bind(this.store);

  getValue() {
    return this.store.getValue();
  }

  ngOnDestroy(): void {
    this.store.destroy();
  }
}
