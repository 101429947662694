import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { map, take } from 'rxjs';
import { ExamdojoAuthService } from './abstract-auth.service';

export const unauthenticatedGuard = () => {
  const authService = inject(ExamdojoAuthService);
  const router = inject(Router);

  return authService.currentUser$.pipe(
    take(1),
    map((isAuthenticated) => {
      if (!isAuthenticated) {
        return true;
      }
      return router.createUrlTree(['/']);
    }),
  );
};
