import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgModule } from '@angular/core';
import { CopyToClipboardToastDirective } from './copy-to-clipboard-toast.directive';

@NgModule({
  declarations: [CopyToClipboardToastDirective],
  imports: [ClipboardModule],
  exports: [CopyToClipboardToastDirective, ClipboardModule],
})
export class CopyToClipboardToastModule {}

export { CopyToClipboardToastModule as CopyToClipboardModule };
