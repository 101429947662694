import { ChangeDetectionStrategy, Component, NgZone, OnDestroy, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatError } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { emailValidator, ErrorMessages } from '@examdojo/core/form';
import { FormConfirmFn, SubmitButtonDirective } from '@examdojo/core/form-submit-button';
import { IconComponent } from '@examdojo/core/icon';
import { ButtonComponent } from '@examdojo/ui/button';
import { TextInputComponent } from '@examdojo/ui/input';
import { OteInputComponent } from '@examdojo/ui/ote-input/ote-input.component';
import { TopHeaderComponent } from '@examdojo/ui/top-header/top-header.component';
import { IonButton } from '@ionic/angular/standalone';
import { filter, tap } from 'rxjs';
import { ExamdojoAuthService } from '../abstract-auth.service';

@Component({
  selector: 'dojo-sign-in-otp',
  templateUrl: './sign-in-otp.component.html',
  styleUrl: './sign-in-otp.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatButtonModule,
    MatError,
    ReactiveFormsModule,
    IconComponent,
    TextInputComponent,
    SubmitButtonDirective,
    ButtonComponent,
    TopHeaderComponent,
    IonButton,
    OteInputComponent,
  ],
})
export class SignInOTPComponent implements OnDestroy {
  constructor(
    private readonly authService: ExamdojoAuthService,
    private readonly router: Router,
    private readonly ngZone: NgZone,
  ) {
    this.submitOtpFormWhenValid().pipe(takeUntilDestroyed()).subscribe();
  }

  error = '';

  otpResendTimer = signal(0);
  otpResendTimerInterval = 30;
  otpResendTimerIntervalRef: number | null = null;

  readonly emailErrorMessages: ErrorMessages = {
    required: 'Email is required',
    email: 'Enter a valid email',
  };

  readonly emailForm = new FormGroup(
    {
      email: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required, emailValidator],
      }),
    },
    {
      updateOn: 'submit',
    },
  );

  readonly otpErrorMessages: ErrorMessages = {
    required: 'Code is required',
  };

  readonly otpForm = new FormGroup(
    {
      code: new FormControl('', {
        nonNullable: true,
        validators: [Validators.required],
      }),
    },
    {
      updateOn: 'change',
    },
  );

  otpCodeHasBeenSent = false;

  ngOnDestroy() {
    if (this.otpResendTimerIntervalRef) {
      clearInterval(this.otpResendTimerIntervalRef);
    }
  }

  async sendOTPCode(emailForm: typeof this.emailForm = this.emailForm) {
    if (emailForm.invalid) {
      emailForm.markAllAsTouched();
      return;
    }

    this.resetOtpResendTimer();

    const { email } = emailForm.getRawValue();

    const { error } = await this.authService.signInWithOTP(email);

    this.error = error?.message ?? '';

    return this.error;
  }

  changeEmail() {
    this.error = '';

    this.emailForm.reset();
    this.otpForm.reset();

    this.otpCodeHasBeenSent = false;
  }

  maskEmail(email: string): string {
    const [localPart, domain] = email.split('@');
    if (!localPart || !domain) {
      return email;
    }

    return `${localPart[0]}*@${domain}`;
  }

  goBack() {
    window.history.back();
  }

  readonly otpConfirmFn: FormConfirmFn<typeof this.otpForm> = (form) => {
    this.handleOTPVerification(form, this.emailForm.controls.email.value);
  };

  readonly emailConfirmFn: FormConfirmFn<typeof this.emailForm> = async (form) => {
    const error = await this.sendOTPCode(form);

    if (!error) {
      this.otpCodeHasBeenSent = true;
    }
  };

  private submitOtpFormWhenValid() {
    return this.otpForm.statusChanges.pipe(
      filter((status) => status === 'VALID'),
      tap(() => {
        this.handleOTPVerification(this.otpForm, this.emailForm.controls.email.value);
      }),
    );
  }

  private async handleOTPVerification(form: FormGroup, email: string) {
    if (form.invalid) {
      form.markAllAsTouched();
      return;
    }
    const { code } = form.getRawValue();

    const { error } = await this.authService.verifyOTP(email, code);
    if (error) {
      this.error = error.message;
    } else {
      this.error = '';

      this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
    }
  }

  private resetOtpResendTimer() {
    this.otpResendTimer.set(this.otpResendTimerInterval);

    if (this.otpResendTimerIntervalRef) {
      clearInterval(this.otpResendTimerIntervalRef);
    }

    this.ngZone.runOutsideAngular(() => {
      this.otpResendTimerIntervalRef = window.setInterval(() => {
        this.ngZone.run(() => {
          this.otpResendTimer.update((v) => v - 1);
        });
      }, 1000);
    });
  }
}
