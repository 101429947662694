import { ChangeDetectionStrategy, Component } from '@angular/core';
import { faMessageExclamation } from '@fortawesome/pro-light-svg-icons';
import { FullscreenMessageComponent } from '@examdojo/core/fullscreen-message';
import { provideFaIcons } from '@examdojo/icons';

@Component({
  selector: 'y42-not-found',
  templateUrl: './not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: { class: 'flex flex-col h-full justify-center' },
  imports: [FullscreenMessageComponent],
})
export class NotFoundComponent {
  constructor() {
    provideFaIcons([faMessageExclamation]);
  }
}
