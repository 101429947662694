import { inject, Injectable, Type } from '@angular/core';
import { Optional } from '@examdojo/core/typescript';
import { ModalController, Platform } from '@ionic/angular/standalone';
import type { ModalOptions } from '@ionic/core/components';

export type DialogOptions = Optional<ModalOptions, 'id' | 'component' | 'componentProps'>;

@Injectable()
export abstract class AbstractDialogService<Input = void, Output = boolean, C extends Type<unknown> = Type<unknown>> {
  readonly modalCtrl = inject(ModalController);
  readonly platform = inject(Platform);

  abstract readonly id: string;
  protected abstract readonly component: C;
  protected readonly componentProps: Partial<InstanceType<C>> = {};

  protected readonly options?: DialogOptions;

  dialogRef?: HTMLIonModalElement;
  dialogInput?: Input;
  readonly isMobile = this.platform.is('mobile');
  readonly isIpad = this.platform.is('ipad');

  protected getPlatformSpecificOptions(isSingleBreakpoint = false): Partial<ModalOptions> {
    if (this.isMobile && !this.isIpad) {
      return isSingleBreakpoint
        ? { initialBreakpoint: 1, breakpoints: [1] }
        : { initialBreakpoint: 1, breakpoints: [0, 1] };
    }
    // no breakpoints for desktop
    return {};
  }

  private readonly defaultModalConfig = {
    cssClass: 'auto-height action-sheet-on-mobile',
    canDismiss: true,
    backdropDismiss: true,
    showBackdrop: true,
    handle: false,
  } satisfies Partial<ModalOptions>;

  async openDialog({
    input,
    options,
    isSingleBreakpoint = false,
  }: { input?: Input; options?: DialogOptions; isSingleBreakpoint?: boolean } = {}): Promise<Output | undefined> {
    this.dialogInput = input;

    const platformSpecificOptions = this.getPlatformSpecificOptions(isSingleBreakpoint);

    this.dialogRef = await this.modalCtrl.create({
      ...this.defaultModalConfig,
      ...platformSpecificOptions,
      id: this.id,
      component: this.component,
      componentProps: this.componentProps,
      ...(this.options ?? {}),
      ...(options ?? {}),
    });

    await this.dialogRef.present();

    const result = await this.dialogRef.onDidDismiss<Output>();

    return result.data;
  }

  dismiss(data: Output) {
    return this.dialogRef?.dismiss(data, '');
  }
}
