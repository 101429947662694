<ion-button
  class="ion-button"
  [class.small]="small()"
  [class.micro]="micro()"
  [class.ion-danger]="danger()"
  [expand]="expand()"
  [disabled]="disabled() || pending"
  [fill]="computedFill()"
  [type]="type()"
  [color]="color()"
>
  <ng-content></ng-content>
  @if (pending) {
    <div class="pending-layer">
      <ion-spinner name="crescent" class="h-4 w-4" color="dark"></ion-spinner>
    </div>
  }
</ion-button>
