import { ClipboardModule } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IconModule } from '@examdojo/core/icon';
import { CopyToClipboardToastModule } from './copy-to-clipboard-toast.module';

@Component({
  selector: 'y42-copy-to-clipboard[value]',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatButtonModule, ClipboardModule, CopyToClipboardToastModule, MatTooltipModule, IconModule],
})
export class CopyToClipboardComponent {
  @Input() value!: string | number;

  @Input() iconSize = 16;
}
